import React, {useContext, useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import useAccount from "../hooks/account";
import {useNavigate} from "react-router-dom";
import {AuthorizationContext} from "../../../account/context";

const PromijeniLozinku = () => {
    const {setAuthorized, setTokenData, isAuthorized, isLoaded, tokenData} = useContext(AuthorizationContext);
    const {register, handleSubmit, setValue, getValues} = useForm();
    const [oldPasswordSet, setOldPasswordSet] = useState(false);
    const [newPasswordSet, setNewPasswordSet] = useState(false);
    const [invalidPassword, setInvalidPassword] = useState(false);
    const [newPasswordConfirmSet, setNewPasswordConfirmSet] = useState(false);
    const [invalidInput, setInvalidInput] = useState(true);
    const [nonMatchingPasswords, setNonMatchingPasswords] = useState(false);
    const [loading, setLoading] = useState(false);
    const [incorrectOldPassword, setIncorrectOldPassword] = useState(false);
    const [submitFailed, setSubmitFailed] = useState(false);

    const { changeUserPassword } = useAccount();
    let navigate = useNavigate();

    useEffect(() => {
        if(isLoaded && !isAuthorized) navigate("/nalog");
    }, [isLoaded]);


    useEffect(() => {
        if (!oldPasswordSet ||
            !newPasswordSet ||
            !newPasswordConfirmSet ||
            nonMatchingPasswords ||
            invalidPassword ||
            incorrectOldPassword
        ) {
            setInvalidInput(true);
        } else {
            setInvalidInput(false);
        }
    }, [
        oldPasswordSet,
        newPasswordSet,
        newPasswordConfirmSet,
        nonMatchingPasswords,
        incorrectOldPassword,
        getValues('oldPassword'),
        getValues('newPassword'),
        getValues('newPasswordConfirmation')
    ])

    const validatePassword = (pass) => {
        if(pass.trim().length<8) return false;
        if(pass.trim().length>32) return false;
        if(!pass.trim().match(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#!@$%'\*()\]^\[&+.?"\\,/:;<>^_`{|}-]).{8,}$/)) return false;
        return true;
    };

    const onInputChangeValue = (event) => {
        setValue(event.target.name, event.target.value);
        setSubmitFailed(false);

        if (event.target.name === "oldPassword") {
            setIncorrectOldPassword(false);
            if(event.target.value.length > 0) {
                setOldPasswordSet(true);
            } else {
                setOldPasswordSet(false);
            }
        } else if (event.target.name === "newPassword") {
            if(event.target.value.length > 0 && !validatePassword(event.target.value)) {
                setInvalidPassword(true);
            } else {
                setInvalidPassword(false);
            }
            if (event.target.value.length > 0) {
                setNewPasswordSet(true);
                if (newPasswordConfirmSet && event.target.value !== getValues('newPasswordConfirmation')) {
                    setNonMatchingPasswords(true);
                } else {
                    setNonMatchingPasswords(false);
                }
            } else {
                setNewPasswordSet(false);
            }
        } else if (event.target.name === "newPasswordConfirmation") {
            if (event.target.value.length > 0) {
                setNewPasswordConfirmSet(true);
                if (newPasswordSet && event.target.value !== getValues('newPassword')) {
                    setNonMatchingPasswords(true);
                } else {
                    setNonMatchingPasswords(false);
                }
            } else {
                setNewPasswordConfirmSet(false);
            }
        }
    }

    const onSubmit = async (data) => {
        setLoading(true);
        try {
            const response = await changeUserPassword({
                newPassword: data.newPassword,
                oldPassword: data.oldPassword
            });
            if (response.status === 200) {
                navigate('/nalog/promijeni-lozinku/uspjesno');
            }
            setLoading(false);
        } catch (ex) {
            if (ex.response.status === 400) {
                setIncorrectOldPassword(true);
            } else {
                setSubmitFailed(true);
            }
            setLoading(false);
        }
    }

    return <div className="lightContent center">
        <div className="wizardCenterArea">
            <div className="mainTitle spacer spacerUp">Promijeni lozinku</div>
            <div className="standardText">
                Odaberi i kreiraj novu lozinku koja ćeš koristiti za prijavu na svoj nalog. Lozinka mora sadržavati minimalno
                8 znakova uključujući bar jedno veliko slovo, malo slovo, broj i specijalni znak.<br/><br/>
                Radi potvrđivanja identiteta prvo unesi svoju staru lozinku.
                Nakon toga unesi novu lozinku, a zatim ju potvrdi ponovnim upisivanjem u polje ispod.
            </div>
            <form noValidate onSubmit={handleSubmit(onSubmit)}>
                <div className="standardInputWrapper">
                    <label>Stara lozinka</label>
                    <input
                        className="full"
                        type="password"
                        name='oldPassword'
                        onChange={onInputChangeValue}
                        required
                    />
                    <i className="fa fa-lock fa-lg"></i>
                </div>
                <div className={"validationErrorMessage"+(incorrectOldPassword ? " invalid" : "")}><i className="fa fa-warning"></i> Unesena lozinka nije tačna</div>
                <div className="standardInputWrapper">
                    <label>Nova lozinka</label>
                    <input
                        className="full"
                        type="password"
                        name='newPassword'
                        onChange={onInputChangeValue}
                        required
                    />
                    <i className="fa fa-lock fa-lg"></i>
                </div>
                <div className={"validationErrorMessage"+(invalidPassword ? " invalid" : "")}><i className="fa fa-warning"></i> Lozinka mora sadržavati bar jedno veliko slovo, malo slovo, broj i specijalni karakter.</div>
                <div className="standardInputWrapper">
                    <label>Potvrdi novu lozinku</label>
                    <input
                        className="full"
                        type="password"
                        name="newPasswordConfirmation"
                        onChange={onInputChangeValue}
                        required
                    />
                    <i className="fa fa-lock fa-lg"></i>
                </div>
                <div className={"validationErrorMessage"+(nonMatchingPasswords ? " invalid" : "")}><i className="fa fa-warning"></i> Unesene lozinke se ne podudaraju</div>
                <div className="centerWrapper">
                    <div className={"validationErrorMessage" + (submitFailed ? " invalid" : "")}><i className="fa fa-warning"></i> Došlo je do nepoznate greške i lozinka nije promijenjena!</div>
                    <input
                        className={
                            "mainActionButton center" +
                            (invalidInput ? " invalid" : "")
                        }
                        type="submit"
                        value={"Pošaljite"}
                    />
                </div>
            </form>
        </div>
    </div>
}

export default PromijeniLozinku;