import React from 'react';
import {NavLink} from "react-router-dom";
import Slideshow from "../components/slideshow";
import 'react-slideshow-image/dist/styles.css'

const Home = (props) => {
    return (
        <div className="homePage">
            <Slideshow />
            <div className="about">
                <div className="aboutOverlay">
                    <div className="aboutText">
                        <div className="aboutTitle">Dosada OFF.<br />Zabava ON!</div>
                        <div className="aboutContentWrapper">
                            <div className="aboutContent">Odaberi <b>ON TV</b> i uživaj u Engleskoj premier ligi na Arena Sportu, ali i drugim premium sportskim sadržajima, najboljim filmovima,
                                serijama i videotekama sa mobilnih uređaja ili velikih ekrana na Android TV prijemnicima, bilo kada i bilo gdje u BiH.</div>
                            <div className="aboutContent">Prijava je jednostavna, bez ugovorne obaveze, uz online uplate i mogućnost deaktivacije kad god poželiš.</div>
                        </div>
                        <div className="aboutButtons">
                            <div className="aboutCTA"><NavLink to="/paketi" className="ctaLink"><i className="fa fa-play-circle"></i>Pretplati se</NavLink></div>
                            <div className="downloadCTA"><a href="#appLinks" className="downloadLink"><i className="fa fa-download"></i>Preuzmi aplikaciju</a></div>
                        </div>
                        <div className="aboutImage">
                            <img src="/resources/images/first-cover-new.png" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="benefits">
                <div className="benefitCard">
                    <div className="benefitCardIcon iconChannels"></div>
                    <div className="benefitCardContent">
                        <div className="benefitCardTitle">Više od 150 najboljih TV kanala</div>
                        <div className="benefitCardText">Uživaj u bogatoj ponudi sportskih, filmskih, serijskih, dječijih i informativnih TV kanala.</div>
                    </div>
                </div>
                <div className="benefitCard">
                    <div className="benefitCardIcon iconCatchup"></div>
                    <div className="benefitCardContent">
                        <div className="benefitCardTitle">72h unazad</div>
                        <div className="benefitCardText">Sada su propušteni sadržaji lako dostupni kroz TV vodič ili TV katalog.</div>
                    </div>
                </div>
                <div className="benefitCard">
                    <div className="benefitCardIcon iconRec"></div>
                    <div className="benefitCardContent">
                        <div className="benefitCardTitle">Snimalica sadržaja</div>
                        <div className="benefitCardText">Ne propusti nijedan sadržaj, snimi ono što te zanima i gledaj kasnije.</div>
                    </div>
                </div>
                <div className="benefitCard">
                    <div className="benefitCardIcon iconRocket"></div>
                    <div className="benefitCardContent">
                        <div className="benefitCardTitle">Napredne funkcionalnosti</div>
                        <div className="benefitCardText">Lako pronađi najbolje sadržaje uz naše preporuke, nastavi gledati film ili seriju ili napravi svoju listu omiljenih kanala.</div>
                    </div>
                </div>
                <div className="benefitCard">
                    <div className="benefitCardIcon iconVod"></div>
                    <div className="benefitCardContent">
                        <div className="benefitCardTitle">Videoteke</div>
                        <div className="benefitCardText">Nekoliko hiljada najboljih naslova ti je dostupno u svakom trenutku u našim videotekama.</div>
                    </div>
                </div>
                <div className="benefitCard">
                    <div className="benefitCardIcon iconMulti"></div>
                    <div className="benefitCardContent">
                        <div className="benefitCardTitle">Gledaj istovremeno na više uređaja</div>
                        <div className="benefitCardText">Odaberi paket po svojoj mjeri i gledaj ON TV na 2 ili 4 uređaja istovremeno.</div>
                    </div>
                </div>
                <div className="benefitCard">
                    <div className="benefitCardIcon iconNoContract"></div>
                    <div className="benefitCardContent">
                        <div className="benefitCardTitle">Bez ugovorne obaveze</div>
                        <div className="benefitCardText" >ON TV možeš otkazati u svakom trenutku bez komplikovane papirologije i procedura.</div>
                    </div>
                </div>
            </div>
            <section id="appLinks" name="appLinks" className="appLinks">
                <div className="appLinksOverlay">
                    <div className="appLinksTitle">Preuzmi aplikaciju</div>
                    <div className="appLinksContent">
                        <div className="appLinkArea">
                            <div className="appLinkAreaContent"><a href="https://play.google.com/store/apps/details?id=ba.ontv.app.androidtv" target="_blank" className="aboutBadge androidTv">Dostupno na Google PlayStore</a></div>
                            <div className="appLinkAreaTitle">Android TV</div>
                        </div>
                        <div className="appLinkArea">
                            <div className="appLinkAreaContent"><a href="https://play.google.com/store/apps/details?id=ba.ontv.app" target="_blank" className="aboutBadge playStore">Dostupno na Google PlayStore</a></div>
                            <div className="appLinkAreaTitle">Android telefon i tablet</div>
                        </div>
                        <div className="appLinkArea">
                            <div className="appLinkAreaContent"><a href="https://apps.apple.com/ba/app/on-tv-bih/id1632197904" target="_blank" className="aboutBadge appStore">Dostupno na Apple AppStore</a></div>
                            <div className="appLinkAreaTitle">iPhone i iPad</div>
                        </div>
                        <div className="appLinkArea">
                            <div className="appLinkAreaContent"><a href="https://appgallery.huawei.com/app/C106636725" target="_blank" className="aboutBadge appGallery">Dostupno na Huaweii AppGallery</a></div>
                            <div className="appLinkAreaTitle">Huawei telefon i tablet</div>
                        </div>
                    </div>
                </div>
            </section>
        </div>

    );
};

export default Home;