import React from 'react';
import {NavLink} from "react-router-dom";

const TwoLineLink = (params) => {
    return (
        <NavLink to={params.link} className="twoLineLink">
            <span className="twoLineLinkLeft">
                <span className="twoLineLinkUp">{params.textUp}</span>
                <span className="twoLineLinkDown">{params.textDown}</span>
            </span>
            <span className="twoLineLinkRight"><i className="fa fa-arrow-circle-right"></i></span>
        </NavLink>
    );
};

export default TwoLineLink;