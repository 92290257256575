import fetch from 'axios';
import { config as getConfig } from '../environments/environments';
import status from './status';
import keys from "./keys";
import {decodeJwtAndGetProperty} from "./util";
import {refreshToken} from "../account/account.service";

const baseUrl = getConfig().api.appEndpoint;
const baseConfig = {
    baseURL: baseUrl,
};


const createInstance = (config) => {
    const instance = fetch.create(config);
    instance.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
    instance.defaults.headers.common['Content-Type'] = 'application/json';
    instance.interceptors.request.use(
        async (config) => {
            if (config.url === '/refresh' || config.url === '/login') {
                delete config.headers.common['Authorization'];
                return config;
            }
            const token = await localStorage.getItem(keys.MTEL_ID_TOKEN);
            if (!!token) {
                config.headers.common['Authorization'] = `Bearer ${token}`;
            }
            return config;
        },
        (error) => {
            Promise.reject(error);
        }
    );
    return instance;
};

const instance = createInstance(baseConfig);

instance.interceptors.request.use((request) => {
    return request;
});

instance.interceptors.response.use(
    (response) => {
        return response;
    },
    async function (error) {
        const originalRequest = error.config;
        if (!error.response || error.response.status !== status.UNAUTHORIZED) {
            throw error;
        }
        if (originalRequest.url === `/refresh` || originalRequest.url === '/logout') {
            throw error;
        }
        if (error.response.status === status.UNAUTHORIZED && !originalRequest._retry) {
            originalRequest._retry = true;
            try {
                const uniqueDeviceId = await localStorage.getItem(keys.MTEL_ID_DEVICE_ID);
                const oldMtelIdToken = await localStorage.getItem(keys.MTEL_ID_TOKEN);

                let authenticationType = oldMtelIdToken
                    ? await decodeJwtAndGetProperty('authentication_type', keys.MTEL_ID_TOKEN)
                    : null;
                if (authenticationType === 1) {
                    const {
                        data: { token },
                    } = await refreshToken({ devicePassword: uniqueDeviceId, token: oldMtelIdToken });
                    await localStorage.setItem(keys.MTEL_ID_TOKEN, token);
                    instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
                    originalRequest.headers['Authorization'] = `Bearer ${token}`;
                    return instance(originalRequest);
                }
            } catch (error) {
                await localStorage.removeItem(keys.MTEL_ID_TOKEN);
                delete instance.defaults.headers.common['Authorization'];
                throw error;
            }
        }
        throw error;
    }
);


export default {
    baseUrl,
    service(removeAuthorization) {
        if (removeAuthorization) {
            delete instance.defaults.headers.common['Authorization'];
        }
        return instance;
    },
};
