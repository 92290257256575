/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
import React, {useState, useEffect} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import DocumentTitle from "react-document-title";


const TvKanali = () => {
    const [iconData, setIconData] = useState([]);
    const [currentBtn, setCurrentBtn] = useState('All');

    let location = useLocation();
    let navigate = useNavigate();

    const handleButtons = (e) => {
        let btn = e.target.value;
        if (btn === "Start") {
            navigate("/tv-kanali?paket=starton");
            setCurrentBtn("Start");
        } else if (btn === "Max") {
            navigate("/tv-kanali?paket=maxon");
            setCurrentBtn("Max");
        } else {
            navigate("/tv-kanali");
            setCurrentBtn("All");
        }
    }


    useEffect(() => {
        fetch("resources/icons.json", {
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        })
            .then((res) => res.json())
            .then((icon) => {

                if (location.search.indexOf("starton") > 0) {
                    setCurrentBtn("Start");
                    document.getElementById("startOnBtn").focus();
                } else if (location.search.indexOf("maxon") > 0) {
                    setCurrentBtn("Max");
                    document.getElementById("maxOnBtn").focus();
                }

                if (currentBtn === "Start") {
                    const filtered = icon.filter((iconData) => {
                        return (iconData.start === true)
                    })
                    setIconData(filtered);

                } else if (currentBtn === "Max") {
                    const filtered = icon.filter((iconData) => {
                        return (iconData.max === true)
                    })
                    setIconData(filtered);
                    document.getElementById("maxOnBtn").focus()
                } else if (currentBtn === "All") {
                    setIconData(icon);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }, [currentBtn]);


    return (
        <DocumentTitle title='ON TV > TV Kanali'>
            <div className="iconContainer">
                <div className="tvChannelBackground">
                    <div className="subheaderMain">
                        <div className="promoTitle">
                            <div className="promoTitleTop">Više od</div>
                            <div className="promoTitleMiddle"><span className="red">150</span> najboljih</div>
                            <div className="promoTitleBottom">TV kanala</div>
                            <div className="promoTitleDescription">Uz ON TV uživaj u bogatoj ponudi sportskih, filmskih,
                                serijskih, dječijih i informativnih TV kanala.
                            </div>
                        </div>
                        <div className="packageType">
                            <button className="btnType" onClick={handleButtons} value="All">Svi kanali</button>
                            <button id="startOnBtn" className="btnType" onClick={handleButtons} value="Start">StartON
                                paket
                            </button>
                            <button id="maxOnBtn" className="btnType" onClick={handleButtons} value="Max">MaxON paket
                            </button>
                        </div>
                    </div>
                </div>
                <div className="contentMain">
                    {iconData.map(function (object) {
                        return (
                            <div className="singleChannel">
                                <div className="singleChannelIcon"
                                     style={{backgroundImage: "url(" + object.landscape + ")"}}></div>
                                <div className="iconText">{object.name}</div>
                            </div>
                        );
                    })}
                </div>
            </div>
        </DocumentTitle>
    );
};

export default TvKanali;
