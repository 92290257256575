import React, {useRef} from 'react';
import { NavLink } from "react-router-dom";
import {deleteCard, fetchPaymentData, setCardAsPrimary} from "../account/account.service";
import Modal from "./modal";
import ConfirmDialog from "./confirm-dialog";

const PaymentCard = (props) => {

    const confirmDialog = useRef(null);

    const deselectAll = () => {
        Array.from(document.getElementsByClassName("paymentCardArea")).forEach(function (itm){
            itm.classList.remove("selected");
        })
    }

    const selectCard = (event) => {
        event.stopPropagation();
        let areaEl = event.currentTarget.parentElement;
        Array.from(document.getElementsByClassName("paymentCardArea")).forEach(function (itm){
            itm.classList.remove("selected");
        })

        areaEl.classList.add("selected");
    }

    const confirmCardDelete = async ()=>{
        console.log(props.data.id);
        let result = await deleteCard(props.data.id);
        props.refresh();
    }

    const openDeleteConfirm = () => {
        confirmDialog.current.open();
    }

    const setDefault = async () => {
        deselectAll();
        let result = await setCardAsPrimary(props.data.id);
        props.refresh();

    }


    return (
        <div className="paymentCardArea">
            <div id={"paymentCard"+props.data.id} className="paymentCard" onClick={selectCard}>
                <div className={"paymentCardLogo "+ props.data.creditCardTypeCode}></div>
                {props.data.primaryCard?<div className="paymentCardDefault"><i className="fa fa-check-circle"></i>Podrazumijevana</div>:""}
                <div className="paymentCardNumber">{props.data.cardNumber}</div>
                <div className="paymentCardOwner">{props.data.fullName}</div>
                <div className="paymentCardExpire">{props.data.expirationDateMm+"/"+props.data.expirationDateYy}</div>
            </div>
            <div className="cardActionArea">
                <div id={"deleteCard-"+props.data.id} className="deleteCardBtn cardActionBtn" onClick={openDeleteConfirm}>Obriši</div>
                {!props.data.primaryCard?<div className="setDefaultCardButton cardActionBtn" onClick={setDefault}>Postavi kao podrazumjevanu</div>:""}
            </div>
            <ConfirmDialog ref={confirmDialog} title="Obriši karticu" body={"Da li sigurno želiš obrisati karticu "+props.data.cardNumber} dialogConfirm={confirmCardDelete}>
            </ConfirmDialog>
        </div>

    );
};

export default PaymentCard;