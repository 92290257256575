import React, {useEffect, useState} from 'react';
import parse from "html-react-parser";

const UsloviPlacanja = () => {
    const [termsText, setTermsText] = useState("");

    useEffect(() => {
        fetch(`/resources/terms.html`)
            .then(res => res.text()).then((response)=>{
                setTermsText(response);
            });
    }, []);

    return (
        <div className="lightContent center">
            <div className="fullTextArea">
                <div className="mainTitle spacer spacerUp">Uslovi plaćanja i politika privatnosti</div>
                <div className="termsContent">{parse(termsText)}</div>
            </div>
        </div>
    );
};

export default UsloviPlacanja;