import React,{useState, useEffect} from 'react'
import {NavLink, useNavigate} from "react-router-dom";
import {useForm} from "react-hook-form";
import keys from "../../../common/keys";
import {approveCodeForForgotPassword, resendCodeForForgotPassword, resendEmail} from "../../../account/account.service";

const ResetovanjeKodPotvrdaEmail = () => {
  const {register, handleSubmit, setValue, getValues} = useForm();
  const [inputInvalid, setInputInvalid] = useState(true);
  const [isLoading,setLoading] = useState(false);
  const [invalidSubmit, setInvalidSubmit] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [resendCodeMessage, setResendCodeMessage] = useState("Pošalji kod ponovo");
  const [resendCodeMessageBlocked, setResendCodeMessageBlocked] = useState(false);

  let navigate = useNavigate();


  useEffect(() => {

      async function checkStep(){
          let regStep = await sessionStorage.getItem(keys.MTEL_ID_FORGOT_PASSWORD_STEP);
          if(!regStep || (regStep!="1"&& regStep!="2")) {
              navigate('/zaboravljena-lozinka');
          }else {
              sessionStorage.setItem(keys.MTEL_ID_FORGOT_PASSWORD_STEP,"2");
          }
      }

      checkStep();


}, []);

const onInputChangeValue = (event) => {
  setInvalidSubmit(false);
  setValue(event.target.name, event.target.value);
  if (getValues("activationCode").length > 0) setInputInvalid(false);
  else setInputInvalid(true);
};

const onSubmit = async (data) => {
  if (inputInvalid) return;
  if(isLoading) return;

  setLoading(true);
  try {
      const response = await approveCodeForForgotPassword({
          uuid: await sessionStorage.getItem(keys.MTEL_ID_FORGOT_PASSWORD_USER_ID),
          otp: data.activationCode
      });
      setLoading(false);
      navigate('/zaboravljena-lozinka/resetovanje-zaboravljene-lozinke'); //TODO WS CODE VALID
  }catch (e){
      setErrorMessage("Unijeli ste neispravan aktivacioni kod."); //TODO ADD ATTEMPT LIMIT
      setInvalidSubmit(true);
      setLoading(false);
  }
}

const resendCode = async () => {
  if(resendCodeMessageBlocked) return;

  try {
      setResendCodeMessageBlocked(true);
      await resendCodeForForgotPassword({
          uuid: sessionStorage.getItem(keys.MTEL_ID_FORGOT_PASSWORD_USER_ID),
          osType:1,
          registrationType: "EMAIL"

      });
      setResendCodeMessage("Novi kod je uspješno poslat...");


  } catch (e) {
      setResendCodeMessage("Greška prilikom slanja novog koda...");

  }

  setTimeout(function (){
      setResendCodeMessageBlocked(false);
      setResendCodeMessage("Pošalji kod ponovo");
  },5000);
}

  return (
    <div id="registerPagePhoneNumber" className="lightContent center">
    <div className="wizardCenterArea">
    <div className="mainTitle spacer spacerUp">Zaboravljena lozinka <span className="mainTileAdditional">&gt; Potvrdi E-Mail</span></div>
                <div className="standardText">Na e-mail adresu <b>{sessionStorage.getItem(keys.MTEL_ID_FORGOT_PASSWORD_USERNAME)}</b> smo poslali aktivacioni kod koji je potrebno unijeti u nastavku:
                </div>
                <form onSubmit={handleSubmit(onSubmit)} noValidate="novalidate">
                    <div className="standardInputWrapper">
                        <label>Aktivacioni kod</label>
                        <input {...register("activationCode")} className="full" type="number" name="activationCode" onChange={onInputChangeValue} required/><i className="fa fa-hashtag fa-lg"></i>
                    </div>
                    <div className={"validationErrorMessage"+(invalidSubmit ? " invalid" : "")}><i className="fa fa-warning"></i> {errorMessage}</div>
                    <div className="centerWrapper">
                        <a onClick={resendCode} className="actionLink">{resendCodeMessage}</a>
                    </div>
                    <div className="centerWrapper">
                        <input className={"mainActionButton center" + (inputInvalid ? " invalid" : "")+(isLoading ? " loading" : "")} type="submit"
                           value={"Nastavi"}/>
                    </div>
                </form>
    </div>

</div>
  )
}

export default ResetovanjeKodPotvrdaEmail;