import React, {useState, useContext, useEffect} from 'react';
import {AuthorizationContext, useAuthorizationStateValue} from "../../../account/context";
import TwoLineLink from "../../../components/inputs/two-line-link";
import {useForm} from "react-hook-form";
import {NavLink, useNavigate} from "react-router-dom";
import keys from "../../../common/keys";
import {continueRegistration, finishRegistration, login} from "../../../account/account.service";
import jwtDecode from "jwt-decode";
import {getBrowserName} from "../../../common/util";

const RegistracijaLicniPodaci= (props) => {
    const {register, handleSubmit, setValue, getValues} = useForm();
    const [inputInvalid, setInputInvalid] = useState(true);
    const [isLoading,setLoading] = useState(false);
    const {setAuthorized, setTokenData} = useContext(AuthorizationContext);

    let navigate = useNavigate();

    useEffect(() => {
        return async () => {
            let regStep = await sessionStorage.getItem(keys.MTEL_ID_REG_STEP);
            if(!regStep || (regStep!="4"&& regStep!="5")) {
                navigate('/kreiraj-nalog');
            }
            sessionStorage.setItem(keys.MTEL_ID_REG_STEP,"5");
        };
    }, []);

    const onInputChangeValue = (event) => {
        setValue(event.target.name, event.target.value);
        if (getValues("name").length > 0 && getValues("lastName").length > 0) setInputInvalid(false);
        else setInputInvalid(true);
    };

    const onSubmit = async (dataIn) => {
        if (inputInvalid) return;
        if(isLoading) return;

        setLoading(true);

        try {
            await continueRegistration({
                uuid: sessionStorage.getItem(keys.MTEL_ID_REG_GUID),
                firstName: dataIn.name,
                lastName: dataIn.lastName,
                password: sessionStorage.getItem(keys.MTEL_ID_REG_PASSWORD),
                registrationType: "EMAIL",
                requireOtp: false,
                osType: 1
            });

            const response = await finishRegistration({
                uuid: sessionStorage.getItem(keys.MTEL_ID_REG_GUID)
            });

            await sessionStorage.setItem(keys.MTEL_ID_REG_PIN,response.data.pin);

            const {
                data: {token},
            } = await login({
                username: sessionStorage.getItem(keys.MTEL_ID_REG_USERNAME),
                password: sessionStorage.getItem(keys.MTEL_ID_REG_PASSWORD),
                deviceType: "ONTV",
                deviceUsername: sessionStorage.getItem(keys.MTEL_ID_REG_USERNAME),
                devicePassword: localStorage.getItem(keys.MTEL_ID_DEVICE_ID),
                deviceName:  getBrowserName(),
            });
            if (token) {
                await localStorage.setItem(keys.MTEL_ID_TOKEN, token);
                setAuthorized(true);
                setTokenData(jwtDecode(token));
            }

            setLoading(false);
            navigate('/kreiraj-nalog/registracija-uspjesna');

        }catch (e){
            console.error(e);
            //TODO IFTIME ERROR SCREEN
        }
    }


    return (
        <div id="registerPagePhoneNumber" className="lightContent center">
            <div className="wizardCenterArea">
                <div className="mainTitle spacer spacerUp">Registruj se <span className="mainTileAdditional">> Lični podaci</span></div>
                <div className="standardText">Na kraju unesi svoje lične podatke kako bi tvoj nalog bio kreiran.
                </div>
                <form onSubmit={handleSubmit(onSubmit)} noValidate="novalidate">
                    <div className="standardInputWrapper">
                        <label>Ime</label>
                        <input {...register("name")} className="full" type="text" name="name" onChange={onInputChangeValue} required/><i className="fa fa fa-user-circle fa-lg"></i>
                    </div>
                    <div className="standardInputWrapper">
                        <label>Prezime</label>
                        <input {...register("lastName")} className="full" type="text" name="lastName" onChange={onInputChangeValue} required/><i className="fa fa fa-user-circle fa-lg"></i>
                    </div>
                    <div className="centerWrapper">
                        <input className={"mainActionButton center" + (inputInvalid ? " invalid" : "")+(isLoading ? " loading" : "")} type="submit"
                           value={"Kreiraj nalog"}/>
                    </div>
                </form>
            </div>

        </div>
    );

}

export default RegistracijaLicniPodaci;