import React, {useState, useContext, useEffect} from 'react';
import {useAuthorizationStateValue} from "../../../account/context";
import {useForm} from "react-hook-form";
import {useNavigate} from "react-router-dom";
import keys from "../../../common/keys";
import {startRegistration} from "../../../account/account.service";
import {formatPhoneNumber, getBrowserName} from "../../../common/util";

const RegistracijaBrojTelefona = (props) => {
    const {register, handleSubmit, setValue, getValues} = useForm();
    const [inputInvalid, setInputInvalid] = useState(true);
    const [isLoading,setLoading] = useState(false);
    const [invalidSubmit, setInvalidSubmit] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const {isAuthorized, tokenData} = useAuthorizationStateValue();

    let navigate = useNavigate();

    useEffect(() => {
        return async () => {
            let regStep = await sessionStorage.getItem(keys.MTEL_ID_REG_STEP);
            if(!regStep || (regStep!="1"&& regStep!="2")) {
                navigate('/kreiraj-nalog');
            }
            sessionStorage.setItem(keys.MTEL_ID_REG_STEP,"2");
        };
    }, []);

    const validatePhoneNumber = (num) => {
        if(!num.trim().match(/^06\d{7,9}$/)) return false;
        return true;
    };

    const onInputChangeValue = (event) => {
        setInvalidSubmit(false);
        setValue(event.target.name, event.target.value);
        if (getValues("phoneNumber").length > 0) setInputInvalid(false);
        else setInputInvalid(true);
    };

    const onSubmit = async (data) => {
        if (inputInvalid) return;
        if(isLoading) return;

        let username = formatPhoneNumber(data.phoneNumber);

        setLoading(true);

        if(!validatePhoneNumber(data.phoneNumber)){
            setInvalidSubmit(true);
            setErrorMessage("Broj telefona mora biti u formatu 06x xxx xxx(x).");
            setLoading(false);
            return;
        }

        try {
            const response = await startRegistration({
                username: username,
                deviceType: "PHONE",
                deviceUsername: username,
                osType: 1, //TODO CHECK, android for some reason....
                registrationType: "PHONE_NUMBER",
                devicePassword: localStorage.getItem(keys.MTEL_ID_DEVICE_ID),
                deviceName: getBrowserName(),
            });
            if (response) {
                await sessionStorage.setItem(keys.MTEL_ID_REG_GUID, response.data);
                await sessionStorage.setItem(keys.MTEL_ID_REG_USERNAME, username)
                setLoading(false);
                navigate('/kreiraj-nalog/potvrdi-broj-telefona');
            }
        }catch (e){
            if (e.response.data?.message) {
                setErrorMessage(e.response.data.message);
            }
            setInvalidSubmit(true);
            setLoading(false);
        }
    }

    return (
        <div id="registerPagePhoneNumber" className="lightContent center">
            <div className="wizardCenterArea">
                <div className="mainTitle spacer spacerUp">Registruj se <span className="mainTileAdditional">> Unesi broj telefona</span></div>
                <div className="standardText">Unesi svoj broj telefona kako bi proces registracije započeo. Nakon toga, biće ti poslata SMS poruka sa jednokratnim aktivacionim kodom koji trebaš unijeti da bi se nastavio proces registracije.
                </div>
                <form onSubmit={handleSubmit(onSubmit)} noValidate="novalidate">
                    <div className="standardInputWrapper">
                        <label>Broj telefona</label>
                        <input {...register("phoneNumber")} className="full" type="number" name="phoneNumber" onChange={onInputChangeValue} required/><i className="fa fa-phone fa-lg"></i>
                    </div>
                    <div className={"validationErrorMessage"+(invalidSubmit ? " invalid" : "")}><i className="fa fa-warning"></i> {errorMessage}</div>
                    <div className="centerWrapper">
                    <input className={"mainActionButton center" + (inputInvalid ? " invalid" : "")+(isLoading ? " loading" : "")} type="submit"
                           value={"Nastavi"}/>
                    </div>
                </form>
            </div>

        </div>
    );

}

export default RegistracijaBrojTelefona;