import React from 'react';
import { NavLink } from "react-router-dom";

const Footer = () => {
    return (
        <div id="footer" className="footer">
            <div className="footerUp">
                <div className="footerLogo"></div>
                <div className="footerLinks">
                    <NavLink to="/paketi" className="footerLink">Paketi</NavLink>
                    <NavLink to="/tv-kanali" className="footerLink">TV kanali</NavLink>
                    <NavLink to="/videoteke" className="footerLink">Videoteke</NavLink>
                    <NavLink to="/nalog" className="footerLink">ON TV nalog</NavLink>
                </div>
                <div className="footerSupport footerSupportInfo">
                    <div className="footerSupportText">E-mail za podršku</div>
                    <div className="footerSupportContent"><a href="mailto:podrska@ontv.ba"><i className="fa fa-envelope-o"></i>podrska@ontv.ba</a></div>
                    <div className="footerSupportContent reverseMargin"><NavLink to="/faq"><i className="fa fa-question-circle"></i>Česta pitanja</NavLink></div>
                </div>
                <div className="footerSupport">
                    <div className="footerSupportText">Pronađi nas na</div>
                    <div className="footerSupportContent">
                        <a href="https://www.facebook.com/mtelbih" target="_blank"><i className="fa fa-facebook-f"></i></a>
                        <a href="https://www.instagram.com/mtelbh/" target="_blank"><i className="fa fa-instagram"></i></a>
                        <a href="https://www.youtube.com/user/mtelimateprijatelje" target="_blank"><i className="fa fa-youtube-play"></i></a>
                    </div>
                    <div className="footerSupportText">Sigurno plaćanje</div>
                    <div className="safePaymentContent">
                        <a className="safePaymentIcon master" href="https://www.mastercard.ba/" target="_blank">MasterCard</a>
                        <a className="safePaymentIcon maestro" href="https://www.mastercard.ba/bs-ba/consumers/find-card-products.html" target="_blank">Maestro</a>
                        <a className="safePaymentIcon idCheck" href="https://www.mastercard.ba/bs-ba/issuers/identity-check.html" target="_blank">Mastercard IDCheck</a>
                        <a className="safePaymentIcon visa" href="https://www.visaeurope.com" target="_blank">Visa</a>
                        <a className="safePaymentIcon visaSecure" href="https://www.visaeurope.com/making-payments/verified-by-visa/" target="_blank">VisaSecure</a>
                    </div>
                </div>
            </div>
            <div className="footerDown">
                <div className="footerCopy">&copy; <a href="https://mtel.ba" target="_blank">m:tel</a>. Sva prava zadržana.</div>
                <div className="footerLegal">
                    <NavLink to="/uslovi-koriscenja" className="legalLink">Uslovi korišćenja</NavLink>
                    <NavLink to="/uslovi-placanja" className="legalLink">Uslovi plaćanja i politika privatnosti</NavLink>
                </div>
            </div>

        </div>
    );
};

export default Footer;