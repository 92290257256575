import React, { useContext, useEffect, useState } from 'react';
import { AuthorizationContext } from "../../account/context";
import { NavLink, useNavigate } from "react-router-dom";
import {deactivateAccount, logout} from "../../account/account.service";
import keys from "../../common/keys";
import { formatDate } from "../../common/util";
import useAccount from "./hooks/account";

const AccountHome = (props) => {

    const {setAuthorized, setTokenData, isAuthorized, tokenData} = useContext(AuthorizationContext);
    let navigate = useNavigate();
    const { accountInfo, getAccountInfo, userCreditCards, primaryCreditCard, getUserCreditCards } = useAccount();

    const logoutHandler = async (data) => {
        setAuthorized(false);
        setTokenData(null);
        localStorage.removeItem(keys.MTEL_ID_TOKEN);
        try {
            await logout();
        }catch (e){
            //LOGOUT ANYWAY
        }
        navigate("/");
    };

    useEffect(() => {
         getAccountInfo();
         getUserCreditCards();
    }, []);

    const getAccountInfoStatusText = () => {
        if(accountInfo){
            switch (accountInfo.status) {
                case "ACTIVE": return "AKTIVNA";
                case "DEACTIVATED": return "DEAKTIVIRANA";
                case "BLOCKED": return "BLOKIRANA";
                case "EXPIRED": return "ISTEKLA";
            }
        }

        return "";
    };

    const goToDeactivate = () => {
        navigate("/nalog/deaktiviraj-ontv");
    }

    const goToReactivate = () => {
        let npo = new Date(accountInfo.nextPaymentOn);
        if(npo>new Date()) navigate("/nalog/reaktiviraj-ontv-bez-placanja");
        else navigate("/nalog/reaktiviraj-ontv")
    }

    const goToExtendSubscription = () => {
        navigate("/nalog/produzi-pretplatu");
    }

    const AccountActivationAction = () => {
        if(!accountInfo) return "";
        else {
            if(accountInfo.status=="ACTIVE") return <button className="mainActionButton deactivateOntvButton" onClick={goToDeactivate}>Deaktiviraj ONTV</button>;
            else if(accountInfo.status=="DEACTIVATED") return <button className="mainActionButton deactivateOntvButton" onClick={goToReactivate}>Reaktiviraj ONTV</button>;
            else return "";
        }
    }

    const ExtendSubscriptionAction = () => {
        if(!accountInfo) return "";
        else if(accountInfo.status=="ACTIVE") {

            var provUnitl = new Date(accountInfo.provisionedUntil);
            var now = new Date();
            if(now.getTime()<provUnitl.getTime() && (provUnitl.getTime()-now.getTime())<15*24*60*60*1000) return <button className="mainActionButton deactivateOntvButton" onClick={goToExtendSubscription}>Produži pretplatu</button>;
            //else return <button className="mainActionButton deactivateOntvButton" onClick={goToExtendSubscription}>Obnovi pretplatu</button>;
        }
        else return "";
    }

    let primaryCardLogoLink;

    switch(primaryCreditCard?.creditCardTypeCode) {
        case "master":
            primaryCardLogoLink = "/resources/images/cardLogos/mastercard.svg";
            break;
        case "maestro":
            primaryCardLogoLink = "/resources/images/cardLogos/maestro.svg";
            break;
        case "visa":
            primaryCardLogoLink = "/resources/images/cardLogos/visa.svg";
            break;
    }

    return (
        <div id="loginPage" className="lightContent block">
        <div>
                <div className="actionHeader">
                    <div className="actionHeaderTitle">ON TV nalog</div>
                    <button className="mainActionButton" onClick={logoutHandler}>Odjavi se</button>
                </div>
            <div className="accountSettingsContainer">
                <div className="settingsPageSection">
                    <h2 className="sectionTitle">Korisnički podaci</h2>
                    <div className="sectionContent">
                        <div className="leftSection">
                            <div className="profilePoint bigger"><b>{tokenData.first_name+" "+tokenData.last_name}</b></div>
                            <div className="profilePoint"><span className="profilePointName"><b>Korisničko ime:</b></span> <span className="profilePointValue">{tokenData.device_username}</span></div>
                            {accountInfo && accountInfo.accountNumber ? <div className="profilePoint"><span className="profilePointName"><b>Korisnički ID: </b></span> <span className="profilePointValue">#{accountInfo?accountInfo.accountNumber:""}</span></div>: ""}
                        </div>
                        <div className="rightSection">
                            <NavLink to="/nalog/promijeni-lozinku">Promijeni lozinku</NavLink><br/>
                            <NavLink to="/nalog/promijeni-pin">Promijeni PIN</NavLink><br/>
                        </div>
                    </div>
                </div>
                <div className="settingsPageSection">
                    <h2 className="sectionTitle">Moj paket</h2>
                    {accountInfo && <>
                        <div className="profilePoint"><span className="profilePointName"><b>Paket: </b></span><span className="profilePointValue">{accountInfo?accountInfo.plan.name:""}</span></div>
                        <div className="profilePoint"><span className="profilePointName"><b>Cijena: </b></span><span className="profilePointValue">{accountInfo?accountInfo.plan.price+"KM mjesečno sa PDV-om":""}</span></div>
                        {accountInfo && accountInfo.status=="ACTIVE" ? <div className="profilePoint"><span className="profilePointName"><b>Pretplata ističe: </b></span> <span className="profilePointValue">{formatDate(new Date(accountInfo.nextPaymentOn))}</span></div>: ""}
                        <div className="profilePoint"><span className="profilePointName"><b>Status pretplate: </b></span><span className="profilePointValue subscriptionStatus">{getAccountInfoStatusText()}</span></div>
                        {
                            /*<div className="flexArea">
                            <ExtendSubscriptionAction />
                        </div>*/
                        }
                        {accountInfo && accountInfo.status=="EXPIRED" ? <div className="centerArea"><NavLink to="/paketi"><button className="mainActionButton center" onClick={goToReactivate}>Obnovi ONTV pretplatu</button></NavLink></div>:""}
                    </>}
                    {!accountInfo && <div className="sectionContent">
                        <div className="sectionNoContentMessage">
                            <div><b>Nemaš aktiviran paket</b></div>
                            <NavLink to="/paketi"><button className="mainActionButton">Odaberi paket</button></NavLink>
                        </div>
                    </div>}
                </div>
                <div className="settingsPageSection">
                    <h2 className="sectionTitle">Plaćanje</h2>
                    {userCreditCards.length > 0 && <div className="sectionContent">
                        <div className="leftSection">
                            <div className="profilePoint"><span className="profilePointName"><b>Platna kartica:</b></span>
                            <div className="creditCardWithLogo">
                                <div className={"creditCardLogo "+primaryCreditCard.creditCardTypeCode}></div>
                                <span className="creditCardNumber">{primaryCreditCard?.cardNumber}</span>
                            </div></div>
                            <div className="profilePoint"><span className="profilePointName"><b>Rok za slijedeću uplatu: </b></span><span className="profilePointValue">{accountInfo?formatDate(new Date(accountInfo.nextPaymentOn)):""}</span></div>
                            <div className="profilePointSmall">Period pretplate na uslugu traje od dana uplate do istog dana u narednom mjesecu, kada je potrebno da izvršite sljedeću uplatu.
                                Ukoliko ponovno plaćanje ne bude izvršeno u roku od 5 dana
                                od isteka datuma uplate za naredni period,
                                usluga će biti deaktivirana i moći će se aktivirati ponovnim izborom i kupovinom paketa usluga, pri čemu će pretplatni period početi od datuma nove kupovine.
                                Ukoliko plaćanje bude izvršeno u roku od 5 dana od isteka datuma za uplatu, pretplatni period traje od dana prvobitne inicijalne uplate do istog dana u sljedećem mjesecu.
                            </div>
                        </div>
                        <div className="rightSection">
                            <NavLink to="moje-kartice">Moje kartice</NavLink><br/>
                            <NavLink to="istorija-uplata">Istorija uplata</NavLink>
                        </div>
                    </div>}
                    {userCreditCards.length === 0 && <div className="sectionNoContentMessage">
                        <div><b>Nemaš sačuvanih kartica</b></div>
                        <NavLink to="/nalog/moje-kartice"><button className="mainActionButton">Dodaj novu karticu</button></NavLink>
                    </div> }
                </div>
                <AccountActivationAction />

            </div>
        </div>
        </div>
    )
};

export default AccountHome;