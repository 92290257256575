import React, {useContext, useEffect, useRef, useState} from 'react';
import {AuthorizationContext} from "../../../account/context";
import {NavLink, useNavigate} from "react-router-dom";
import {fetchAccountInfo, fetchPaymentData} from "../../../account/account.service";
import PaymentCard from "../../../components/payment-card";
import ConfirmDialog from "../../../components/confirm-dialog";
import Modal from "../../../components/modal";
import CardPersonalData from "../../../components/addCard/cardPersonalData";
import CardData from "../../../components/addCard/cardData";
import SavedCardSuccess from "../../../components/addCard/saveCardSuccess";
import SavedCardError from "../../../components/addCard/saveCardFailed";

const MojeKartice = (props) => {

    const {setAuthorized, setTokenData, isAuthorized, isLoaded, tokenData} = useContext(AuthorizationContext);
    const [accountInfo, setAccountInfo] = useState(null);
    const [creditCards, setCreditCards] = useState([]);

    const [addCardStep, setAddCardStep] = useState(1);
    const [cardData, setCardData] = useState(null);

    const modal = useRef(null);
    let navigate = useNavigate();

    const confirmDialog = useRef(null);

    useEffect(() => {
        if(isLoaded && !isAuthorized) navigate("/nalog");
    }, [isLoaded]);

    const fetchCards = async () => {
        const {
            data: { creditCards, mbons },
        } = await fetchPaymentData();

        setCreditCards(creditCards);
    }


    useEffect(() => {
        fetchCards();
    }, []);

    const nextStep = (cardData) => {
        setCardData(cardData);
        setAddCardStep(2);
    }

    const addNewCard = () => {
        setAddCardStep(1);
        modal.current.open();
    }

    const addNewCardSuccess = () => {
        fetchCards();
        setAddCardStep(3);
    }

    const addNewCardError = () => {
        setAddCardStep(4);
    }

    const finishedAdding = () => {
        fetchCards();
        modal.current.close();
    }

    const RenderStep = () => {
        switch(addCardStep){
            case 1: return <CardPersonalData onFinish={nextStep} onError={addNewCardError}/>;
            case 2: return <CardData cardData={cardData} onSuccess={addNewCardSuccess} onError={addNewCardError}/>;
            case 3: return <SavedCardSuccess onFinish={finishedAdding}/>
            case 4: return <SavedCardError modal={modal.current}/>
        }
    }

    return (
        <div className="lightContent block">
            <div>
                <div className="actionHeader">
                    <div className="actionHeaderTitle">Moje kartice</div>
                    <button className="mainActionButton" onClick={addNewCard}>Dodaj karticu</button>
                </div>
                <div className="standardText clear">Dodaj ili obriši svoje platne kartice koje možeš koristiti za plaćanje ON TV usluge.</div>
                <div className="standardText">Svi tvoji podaci, uključujući i podatke o platnim katicama, su
                    zaštićeni u skladu sa najvišim sigurnosnim standardima.</div>
                <div className="cardsSection">
                    {creditCards.length === 0 && <div className="sectionNoContentMessage">
                        <div><b>Nemaš sačuvanih kartica</b></div>
                        <button className="mainActionButton" onClick={addNewCard}>Dodaj novu karticu</button>
                    </div> }
                    {creditCards.map(function(object, i){
                        return <PaymentCard data={object} key={object.id} refresh={fetchCards}/>;
                    })}
                </div>
            </div>
            <Modal ref={modal} target="selectedPlanPrice">
                <RenderStep />
            </Modal>
        </div>
    )
};

export default MojeKartice;