import React, { useEffect, useImperativeHandle, useState, forwardRef, useCallback } from 'react';
import { createPortal } from 'react-dom';

const modalElement = document.getElementById('confirm-root');

export function ConfirmDialog({ title, body, dialogConfirm, defaultOpened = false, target }, ref) {
    const [isOpen, setIsOpen] = useState(defaultOpened);

    const close = useCallback(() => {
        setIsOpen(false)
    },  []);

    const confirmAction = useCallback(() => {
        dialogConfirm();
        setIsOpen(false);
    },  []);


    useImperativeHandle(ref, () => ({
        open: () => setIsOpen(true),
        close
    }), [close])

    const handleEscape = useCallback(event => {
        if (event.keyCode === 27) close()
    }, [close])

    useEffect(() => {
        if (isOpen) document.addEventListener('keydown', handleEscape, false)
        return () => {
            document.removeEventListener('keydown', handleEscape, false)
        }
    }, [handleEscape, isOpen])

    return createPortal(
        isOpen ? (
            <div className="confirmDialog">
                <div className="confirmDialogContent">
                    <div className="confirmDialogHeader">
                        <div className="confirmDialogTitle">{title}</div>
                    </div>
                    <div className="confirmDialogBody">{body}</div>
                    <div className="confirmDialogButtons">
                        <span role="button" className="confirmDialogButton confirmDialogConfirmButton" aria-label="confirm" onClick={confirmAction}>Potvrdi</span>
                        <span role="button" className="confirmDialogButton confirmDialogCancelButton" aria-label="close" onClick={close}>Odustani</span>
                    </div>
                </div>

            </div>
        ) : null,
        modalElement
    )
}

export default forwardRef(ConfirmDialog);