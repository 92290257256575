import React, {useEffect, useRef, useState} from 'react';
import {NavLink, useNavigate} from "react-router-dom";
import {useAuthorizationStateValue} from "../../../account/context";
import {useLocation} from "react-use";
import parse from "html-react-parser";
import {useForm} from "react-hook-form";
import {
    createSubscriptionInPromoPeriod,
    fetchAccountInfo,
    fetchPaymentData,
    fetchPlanList
} from "../../../account/account.service";
import Modal from "../../../components/modal";
import CardPersonalData from "../../../components/addCard/cardPersonalData";
import CardData from "../../../components/addCard/cardData";
import SavedCardSuccess from "../../../components/addCard/saveCardSuccess";
import SavedCardError from "../../../components/addCard/saveCardFailed";
import {getPromoPeriodTitle, isPromoPeriodActive} from "../../../common/plan-util";

const PretplatiSe = (props) => {
    const {register, handleSubmit, setValue, getValues} = useForm();
    const [inputInvalid, setInputInvalid] = useState(true);
    const {isAuthorized, isLoaded, tokenData} = useAuthorizationStateValue();
    const [selectedPlan, setSelectedPlan] = useState({});
    const [selectedCard, setSelectedCard] = useState(null);
    const [addCardStep, setAddCardStep] = useState(1);
    const [cardData, setCardData] = useState(null);
    const [restartMonriForm, setRestartMonriForm] = useState(true);
    const [termsText, setTermsText] = useState("");
    const [useTermsText, setUseTermsText] = useState("");

    const modal = useRef(null);
    const modalTerms = useRef(null);
    const modalUseTerms = useRef(null);

    let navigate = useNavigate();
    let location = useLocation();

    useEffect(() => {
        if(isLoaded && !isAuthorized) navigate("/nalog");
    }, [isLoaded]);

    useEffect(() => {
        fetch(`/resources/terms.html`)
            .then(res => res.text()).then((response)=>{
            setTermsText(response);
        });
    }, []);

    useEffect(() => {
        fetch(`/resources/use-terms.html`)
            .then(res => res.text()).then((response)=>{
            setUseTermsText(response);
        });
    }, []);



    useEffect(() => {

        async function getPlanInfo(){

            const sitePlanDataResponse = await fetch(`/resources/planData-v8.json`);
            const sitePlanData = await sitePlanDataResponse.json();

            const backendPlanDataResponse = await fetchPlanList();
            const backendPlanData = backendPlanDataResponse.data;

            backendPlanData.forEach(el => {
                if(sitePlanData.plans[el.code]) sitePlanData.plans[el.code].backendData = el;
            });

            let query = new URLSearchParams(location.search);
            if (query.get("paket")) {
                let planData = sitePlanData.plans[query.get("paket")];
                if (planData) setSelectedPlan(planData);
                else navigate("/paketi");
            } else navigate("/paketi");

        }

        getPlanInfo();
    }, []);

    const fetchCards = async () => {
        const {
            data: { creditCards, mbons },
        } = await fetchPaymentData();

        if(creditCards && creditCards.length>0){
            creditCards.forEach(function (card){
                if(card.primaryCard) setSelectedCard(card);
            });
            onInputChangeValue();
        }
    }

    useEffect(() => {
        fetchCards();
    }, []);

    useEffect(() => {
        async function accountInfo() {
            try {
                let info = await fetchAccountInfo();
                if(info.data.status!="EXPIRED") navigate("/paketi");
            }catch (ex){
                console.log(ex)
            }
        };

        accountInfo();

    }, []);

    const onSubmit = async (data) => {
        console.log(data);
        console.log(selectedPlan);
        if(inputInvalid) return;
        setInputInvalid(true);
        if(isPromoPeriodActive(selectedPlan)) {
            try {
                let resp = await createSubscriptionInPromoPeriod({
                    plan: selectedPlan.id
                });
                navigate("/pretplata-uspjesna");
            } catch (ex) {
                console.log(ex);
                navigate("/pretplata-greska");
            }
        }else navigate("/potvrdi-pretplatu?plan="+selectedPlan.id);
    }

    const onInputChangeValue = (event) => {
        if(event) setValue(event.target.name, !getValues(event.target.name));
        if (selectedCard && getValues("acceptTerms")==true && getValues("acceptPayTerms")) setInputInvalid(false);
        else setInputInvalid(true);
    };

    const nextStep = (cardData) => {
        console.log(cardData);
        setCardData(cardData);
        setAddCardStep(2);
    }

    const showTerms = () => {
        modalTerms.current.open();
    }

    const showUseTerms = () => {
        modalUseTerms.current.open();
    }

    const addNewCard = () => {
        setAddCardStep(1);
        modal.current.open();
    }

    const addNewCardSuccess = () => {
        fetchCards();
        setAddCardStep(3);
    }

    const addNewCardError = () => {
        setAddCardStep(4);
    }

    const finishedAdding = () => {
        fetchCards();
        modal.current.close();
    }

    const RenderStep = () => {
            switch(addCardStep){
                case 1: return <CardPersonalData onFinish={nextStep} onError={addNewCardError}/>;
                case 2: return <CardData cardData={cardData} onSuccess={addNewCardSuccess} onError={addNewCardError}/>;
                case 3: return <SavedCardSuccess onFinish={finishedAdding}/>
                case 4: return <SavedCardError modal={modal.current}/>
            }
    }

    return (
        <div id="subscriptionConfirmationPage" className="lightContent between">
            <div className="subscribePlanArea">
                <div className="mainTitleLeft">
                    <div className="mainSubtitle">Odabran je</div>
                    <div className="mainTitle">{selectedPlan.name}</div>
                    <div className="mainSubtitle">paket</div>
                </div>
                <div className="selectedPlan">
                    {isPromoPeriodActive(selectedPlan) ? (
                        <div className="selectedPlanPromoPeriod">
                            <b>{getPromoPeriodTitle(selectedPlan)}</b>
                        </div>
                    ) : ""
                    }
                    <div className="selectedPlanServicesList">
                        <div className="selectedPlanServicesListItem">
                            <i className="fa fa-angle-right"></i>
                            <span>{selectedPlan.numChannels}</span> TV kanala <NavLink
                            to={"/tv-kanali?paket=" + selectedPlan.id} target='_blank' className='goToTv'>Lista
                            kanala</NavLink>
                        </div>
                        {selectedPlan.additionalServices ? selectedPlan.additionalServices.map(function (as, j) {
                            return (
                                <div className="selectedPlanServicesListItem">
                                    <i className="fa fa-plus"></i><b>{as.name}</b> {as.type}
                                </div>
                            )
                        }) : ""}
                        <div className="selectedPlanServicesListItem">
                            {selectedPlan.multiscreenDescription ? parse(selectedPlan.multiscreenDescription) : ""}
                        </div>
                        <div className="selectedPlanServicesListItem">
                            {selectedPlan.planSpotlight ? parse(selectedPlan.planSpotlight) : ""}
                        </div>
                    </div>
                </div>
                <div className="serviceDisclamer">
                    Da bi se pretplatili na ON TV uslugu neophodno je registrovati i sačuvati platnu karticu koji možeš koristiti za plaćanje mjesečne naknade za uslugu.
                </div>

            </div>
            <div className="confirmSubscriptionArea">
                <form onSubmit={handleSubmit(onSubmit)} noValidate="novalidate">
                    <div id="selectedPlanPrice" className="selectedPlanPrice">
                        <span className="amount">{selectedPlan.promoPrice}</span>
                        <span className="currency">KM</span>

                        <span className="month" style={{ fontSize: '14px'}}>/mjesečno sa PDV-om u promo periodu do 31.10.<br/></span>
                        <span className="month" style={{ fontSize: '14px'}}>Nakon isteka promo perioda cijena iznosi <b>{selectedPlan.price}KM</b> mjesečno sa PDV-om.</span>
                    </div>
                    <div id="confirmSubscriptionText" className="confirmSubscriptionText">Način plaćanja:</div>
                    {selectedCard ?
                        <div className="cardSelectionArea"><span className={"cardLogo "+selectedCard.creditCardTypeCode}></span><span className="cardNumber">{selectedCard.cardNumber}</span></div>
                        :
                        <div className="newCardButton" onClick={addNewCard}>
                            Unesi platnu karticu
                        </div>
                    }
                    <div className="confirmSubscriptionCheckboxArea">
                        {/*<div className="confirmSubscriptionCheckboxAreaItem">*/}
                        {/*    <input {...register("acceptCardPayment")} type="checkbox" onClick={onInputChangeValue} id="acceptCardPayment" name="acceptCardPayment"/>*/}
                        {/*    <label htmlFor="acceptCardPayment"> Prihvatam da se sa odabrane platne kartice automatski*/}
                        {/*        vrši naplata nakanade za ON TV uslugu jednom mjesečno.</label>*/}
                        {/*</div>*/}
                        <div className="confirmSubscriptionCheckboxAreaItem">
                            <div>
                            <input {...register("acceptTerms")} type="checkbox" onClick={onInputChangeValue} id="acceptTerms" name="acceptTerms"/>
                            <label htmlFor="acceptTerms"> Prihvatam <a href="#" onClick={showUseTerms}>uslove korišćenja</a>.</label>
                            </div>
                            <div>
                            <input {...register("acceptPayTerms")} type="checkbox" onClick={onInputChangeValue} id="acceptPayTerms" name="acceptPayTerms"/>
                            <label htmlFor="acceptPayTerms"> Prihvatam <a href="#" onClick={showTerms}>uslove plaćanja i politiku privatnosti</a>.</label>
                            </div>
                        </div>
                        <input className={"mainActionButton"+(inputInvalid ? " invalid" : "")} type="submit" value={isPromoPeriodActive(selectedPlan)?"Pretplati se":"Nastavi"}/>
                    </div>
                </form>
            </div>
            <Modal ref={modal} target="selectedPlanPrice">
                <RenderStep />
            </Modal>
            <Modal ref={modalTerms} target="selectedPlanPrice">
                <div className="fullTextArea">
                    <div className="mainTitle spacer spacerUp">Uslovi plaćanja i politika privatnosti</div>
                    <div className="termsContent">{parse(termsText)}</div>
                    <div className="spacerUp">&nbsp;</div>
                </div>
            </Modal>

            <Modal ref={modalUseTerms} target="selectedPlanPrice">
                <div className="fullTextArea">
                    <div className="mainTitle spacer spacerUp">Uslovi korišćenja</div>
                    <div className="termsContent">{parse(useTermsText)}</div>
                    <div className="spacerUp">&nbsp;</div>
                </div>
            </Modal>

        </div>
    )
};

export default PretplatiSe;