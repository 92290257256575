import React, {useState} from 'react'
import {NavLink, useNavigate} from "react-router-dom";

const ResetovanjeLozinkeUspjesno = () => {

    let navigate = useNavigate();

    const onSubmit = async (data) => {
        console.log(data);
        navigate('/nalog');
    }

    return (
        <div id="registerPagePhoneNumber" className="lightContent center">
            <div className="wizardCenterArea">
                <div className="mainTitle spacer spacerUp">Loznika je uspješno promjenjena!</div>
                <div className="standardText">Sada možeš da se prijaviš na svoj ON TV nalog.
                </div>
                <div className="centerWrapper">
                    <button className="mainActionButton center" type="submit" onClick={onSubmit}>Prijavi se</button>
                </div>
            </div>

        </div>
    )
}

export default ResetovanjeLozinkeUspjesno;