import React from 'react';

const SavedCardError = (props) => {

    const onClose = () => {
        props.modal.close();
    }

    return (
        <div className="wizardCenterArea">
            <div className="mainTitle spacer spacerUp">Greška</div>
            <div className="standardText">
                Dogodila se greška prilikom unosa kartice. Molimo pokušaj ponovo...
            </div>
            <div className="centerWrapper">
                <button className={"mainActionButton spacer center"} onClick={onClose}>Zatvori</button>
            </div>
        </div>
    );
};

export default SavedCardError;