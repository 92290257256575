import React from 'react';
import {NavLink} from "react-router-dom";

const PretplataGreska = (props) => {
    return (
        <div id="subscriptionErrorPage" className="lightContent">
            <div className="subscribeSuccessArea">
                <div className="mainTitleLeft">
                    <div className="mainTitle">Greška!</div>
                    <div className="mainSubtitle">Dogodila se greška prilkom aktivacije <b>ON TV paketa</b>. Molimo pokušajte kasnije.<br /></div>
                    <div className="mainSubtitle"><br />Ukoliko naplata naknade za odabrani paket nije bila uspješna, molimo kontaktirajte banku izdavaoca Vaše platne kartice.
                    Neki od razloga neuspješne transakcije mogu biti:
                    <ul>
                        <li>neuspješna sigurnosna autorizacija kartice od strane banke,</li>
                        <li>preveliki broj pokušaja korišćenja platne kartice u kratkom vremenskom intervalu,</li>
                        <li>nedovoljan iznos na računu.</li>
                    </ul>
                    <br />
                        Za ostale informacije možete pogledati sekciju <NavLink to={"/faq"}>Česta pitanja</NavLink> ili kontaktirati podršku na email: <a href="mailto:podrska@ontv.ba">podrska@ontv.ba</a>
                    </div>
                </div>
                <NavLink className="backToAccount" to={"/paketi"}>&lt; Nazad na ON TV pakete</NavLink>
            </div>



        </div>
    )
};

export default PretplataGreska;