import api from '../common/app.service';

export const login = ({ username, password, deviceType, deviceUsername, devicePassword, deviceName }) => {
    return api.service(true).post('/login', {
        username,
        password,
        deviceType,
        deviceUsername,
        devicePassword,
        deviceName
    });
};

export const refreshToken = ({ devicePassword, token }) => {
    return api.service(true).post('/refresh', {
        devicePassword,
        token,
    });
};

export const logout = () => {
    return api.service().post('/logout');
};

export const startRegistration = ({
                                      registrationType,
                                      deviceType,
                                      deviceUsername,
                                      devicePassword,
                                      deviceName,
                                      username,
                                      osType,
                                  }) => {
    return api.service(true).post('/start-registration', {
        registrationType,
        deviceType,
        deviceUsername,
        devicePassword,
        deviceName,
        username,
        osType,
    });
};

export const approveEmail = ({ uuid, otp }) => {
    return api.service(true).post('/approve/mail', {
        identificator: uuid,
        mailCode: otp,
    });
};

export const continueRegistration = ({
                                         uuid,
                                         password,
                                         firstName,
                                         lastName,
                                         registrationType,
                                         secondaryUsername,
                                         requireOtp,
                                         osType,
                                     }) => {
    return api.service(true).post('/continue-registration', {
        registrationType,
        identificator: uuid,
        osType,
        password,
        firstName,
        lastName,
        secondaryUsername,
        requireOtp,
    });
};

export const finishRegistration = ({ uuid }) => {
    return api.service(true).post('/finish-registration', {
        identificator: uuid,
    });
};

export const resendEmail = ({ uuid, osType }) => {
    return api.service(true).post('/resend/mail', {
        identificator: uuid,
        osType,
    });
};

export const approveSMS = ({ uuid, otp }) => {
    return api.service(true).post('/approve/sms', {
        identificator: uuid,
        smsCode: otp,
    });
};

export const resendSMS = ({ uuid, osType }) => {
    return api.service(true).post('/resend/sms', {
        identificator: uuid,
        osType,
    });
};

export const fetchPaymentData = () => {
    return api.service().get('/customer/payment-data');
};

export const fetchPaymentInfo = () => {
    return api.service().get('/ontv-payments/payment-info');
};

export const fetchPaymentInfoForNewAccount = (planCode) => {
    return api.service().get('/ontv-payments/new-account/payment-info/'+planCode);
};

export const fetchPaymentHistory = () => {
    return api.service().get('/ontv-payments');
};

export const fetchInoviceData = (num) => {
    return api.service().get('/ontv-payments/'+num);
};

export const createCardRequest = () => {
    return api.service().post('/credit-card');
};

export const createCofPaymentRequest = ({creditCardId,amount,onTvPlanId}) => {
    return api.service().post("/ontv-payments/order", {
        creditCardId:creditCardId,
        amount:amount,
        onTvPlanId:onTvPlanId
    })
}

export const createNewAccountCofPaymentRequest = ({creditCardId,amount,onTvPlanId}) => {
    return api.service().post("/ontv-payments/new-account/order", {
        creditCardId:creditCardId,
        amount:amount,
        onTvPlanId:onTvPlanId
    })
}

export const fetchUserCreditCards = () => {
    return api.service().get('/credit-card');
};

export const checkPin = (pin) => {
    return api.service().post('/customer/check-pin', {
        pin: pin
    });
}

export const changePin = (payload) => {
    return api.service().post('/customer/change-pin', {
        newPin: payload.newPin,
        oldPin: payload.oldPin
    });
}

export const changePassword = (payload) => {
    return api.service().post('/customer/change-password', {
        newPassword: payload.newPassword,
        oldPassword: payload.oldPassword
    });
}

export const sendMonriCardRequest = ({ code, fullName, address, city, zip, phone, country, email }) => {
    return api.service().patch(`/credit-card/${code}`, {
        fullName,
        address,
        city,
        zip,
        phone,
        country,
        email,
    });
};

export const fetchAccountInfo = () => {
    return api.service().get('/on-tv/accountInfo');
};

export const fetchPlanList = () => {
    return api.service().get('/on-tv/plans');
};

export const createSubscriptionInPromoPeriod = ({ plan,promoPeriod }) => {
    return api.service().post(`/on-tv/createSubscriptionInPromoPeriod`, {
        plan
    });
};


export const checkUsernameForForgotPassword = ({username, osType}) =>{
    return api.service(true).post('/forgot-password/check-username', {
        username,
        osType
    })
};

export const resetPassword = ({uuid, password}) => {
    return api.service(true).post('/forgot-password/reset',{
        identificator: uuid,
        password,
    })
};

export const approveCodeForForgotPassword = ({ uuid, otp }) => {
    return api.service(true).post('/forgot-password/approve-code', {
        identificator:uuid,
        code: otp,
    });
};

export const resendCodeForForgotPassword = ({ uuid, registrationType, osType }) => {
    return api.service(true).post('/forgot-password/resend-code', {
        identificator:uuid,
        registrationType,
        osType,
    });
};

export const deleteCard = (creditCardId) => {
    return api.service().delete(`/credit-card/${creditCardId}`);
};

export const setCardAsPrimary = (creditCardId) => {
    return api.service().patch(`/credit-card/primary/${creditCardId}`);
};

export const deactivateAccount = () => {
    return api.service().get('/on-tv/deactivate');
};

export const reactivateAccountWithoutPayment = () => {
    return api.service().get('/on-tv/reactivateWithoutPayment');
};