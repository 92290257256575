import React, {useState, useContext} from 'react';
import StandardInput from "../../components/inputs/standardInput";
import {NavLink, useNavigate} from "react-router-dom";
import {v4 as uuidv4} from 'uuid';
import keys from '../../common/keys';
import {useForm} from 'react-hook-form';
import {login} from "../../account/account.service";
import {AuthorizationContext} from "../../account/context";
import jwtDecode from 'jwt-decode';
import {checkIfPhoneNumber, formatPhoneNumber, getBrowserName} from "../../common/util";

const Login = (props) => {
    const {register, handleSubmit, setValue, getValues} = useForm();
    const [inputInvalid, setInputInvalid] = useState(true);
    const [invalidLogin, setInvalidLogin] = useState(false);
    const [isLoading,setLoading] = useState(false);
    const {setAuthorized, setTokenData} = useContext(AuthorizationContext);

    let navigate = useNavigate();

    const onInputChangeValue = (event) => {
        setInvalidLogin(false);
        setValue(event.target.name, event.target.value);
        if (getValues("username").length > 0 && getValues("password").length > 0) setInputInvalid(false);
        else setInputInvalid(true);
    };


    const onSubmit = async (data) => {
        if (inputInvalid) return;
        if(isLoading) return;

        let username = data.username;
        if(checkIfPhoneNumber(username)) username=formatPhoneNumber(username);

        setLoading(true);
        try {
            const {
                data: {token, pin, firstLogin},
            } = await login({
                username: username,
                password: data.password,
                deviceType: "ONTV",
                deviceUsername: username,
                devicePassword: localStorage.getItem(keys.MTEL_ID_DEVICE_ID),
                deviceName: getBrowserName(),
            });
            if (token) {
                await localStorage.setItem(keys.MTEL_ID_TOKEN, token);
                setAuthorized(true);
                setTokenData(jwtDecode(token));
                setLoading(false);
                navigate('/nalog');
            }
        }catch (e){
            setInvalidLogin(true);
            setLoading(false);
        }
    }

    return (
        <div id="loginPage" className="lightContent">
            <div className="loginArea">
                <div className="mainTitleLeft">
                    <div className="mainTitle">Prijavi se</div>
                    <div className="mainSubtitle">sa svojim ON TV nalogom</div>
                </div>
                <div className="loginForm">
                    <form onSubmit={handleSubmit(onSubmit)} novalidate="novalidate">
                        <div className="standardInputWrapper">
                            <label>E-mail ili broj telefona</label>
                            <input {...register("username")} type="text" name="username" onChange={onInputChangeValue}
                                   required/><i className="fa fa-user-circle fa-lg"></i>
                        </div>
                        <div className="standardInputWrapper">
                            <label>Lozinka</label>
                            <input {...register("password")} type="password" name="password"
                                   onChange={onInputChangeValue} required/><i className="fa fa-lock fa-lg"></i>
                        </div>
                        <div className="actionButtonArea">
                        <input className={"mainActionButton" + (inputInvalid ? " invalid" : "")+(isLoading ? " loading" : "")} type="submit"
                               value={"Prijavi se"}/>
                        <div className={"actionButtonErrorMessage" +(invalidLogin ? "" : " hidden")}><i className="fa fa-warning fa-lg"></i> Neuspješna prijava</div>
                        </div>
                    </form>
                </div>
                <NavLink to="/zaboravljena-lozinka" className="actionLink">Zaboravljena lozinka?</NavLink>
            </div>
            <div className="signupInfoArea">
                <div className="secondaryTitle"><b>Želiš da kreiraš ONTV nalog? Registruj se odmah!</b></div>
                <div className="standardText">ON TV nalog omogućava pristup usluzi ONTV pomoću jedinstvenog korisničkog naloga,
                    ali i jednostavno online plaćanje usluge. Prati korake, kreiraj korisničko ime i lozinku,
                    poveži svoju platnu karticu i uživaj u premium sportskim i zabavnim sadržajima bilo gdje i bilo kada.
                </div>
                <div className="standardText">Možeš se registrovati putem bilo kog broja mobilnog telefona ili e-mail adrese, a ONTV možeš gledati isključivo na prostoru Bosne i Hercegovine.</div>
                <div className="standardText">Svi tvoji podaci, uključujući i podatke o platnim karticama, zaštićeni su u skladu sa najvišim sigurnosnim standardima.
                </div>
                <NavLink to="/kreiraj-nalog" className="secondaryActionButton"><span>Kreiraj ON TV nalog</span><i
                    className="fa fa-arrow-circle-right"></i></NavLink>
            </div>

        </div>
    )
};

export default Login;