import { useNavigate } from "react-router-dom";
import React, {useEffect, useState} from "react";
import {deactivateAccount, fetchAccountInfo} from "../../../account/account.service";
import useAccount from "../hooks/account";

const DeaktivirajOntv = () => {

    let navigate = useNavigate();


    useEffect(() => {
        async function fetchAccountData() {
            try{
                let info = await fetchAccountInfo();
                if(!info.data || info.data.status!="ACTIVE")
                    navigate("/nalog");
            }catch (e){
                navigate("/nalog");
            }
        };
        fetchAccountData();
    }, []);

    const deactivate = async (data) => {
        const result = await deactivateAccount();
        navigate('/nalog/deaktiviraj-ontv/uspjesno');
    }

    return (
        <div id="registerPagePhoneNumber" className="lightContent center">
            <div className="wizardCenterArea">
                <div className="mainTitle spacer spacerUp">Deaktiviraj ON TV</div>
                <div className="standardText">
                    Nakon deaktivacije ON TV naloga i dalje ćeš moći da koristiš servis do kraja obračunskog perioda.</div>
                <div className="standardText">
                    Da li sigurno želiš da deaktiviraš ON TV uslugu?
                </div>
                <div className="centerWrapper">
                    <button className="mainActionButton center" type="submit" onClick={deactivate}>Potvrdi deaktivaciju</button>
                </div>
            </div>

        </div>
    )
}

export default DeaktivirajOntv;