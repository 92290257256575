import React, { useEffect, useState } from "react";
import { Slide } from 'react-slideshow-image';
import {NavLink} from "react-router-dom";

const properties = {
    scale: 1,
    indicators: i => (<li className="indicator"></li>),
    prevArrow: <div></div>,
    nextArrow: <div></div>
};



const Slideshow = () => {

    const [sliderData, setSliderData] = useState([]);

    useEffect(() => {
        fetch(`/resources/sliderData-v7.json`)
            .then(res => res.json()).then((sd) => setSliderData(sd));
    }, []);

    return (
        <div className="slideShow">
            <Slide {...properties} easing="ease">
                {sliderData.map(function(object, i){
                    return (
                        <div className="each-slide">
                            <div className="slideLandscape" style={{'backgroundImage': `url(${object.landscape})`}}>
                                {object.linkTo? <NavLink to={object.linkTo.href} className={"slideLink "+object.linkTo.position+"SlideLink"}>{object.linkTo.text}</NavLink>: ""}
                            </div>
                            <div className="slidePortrait" style={{'backgroundImage': `url(${object.portrait})`}}>
                                {object.linkTo? <NavLink to={object.linkTo.href} className={"slideLink "+object.linkTo.position+"SlideLink"}>{object.linkTo.text}</NavLink>: ""}
                            </div>
                        </div>
                    )
                })}
            </Slide>
        </div>
    )
};

export default Slideshow;