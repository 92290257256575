import React, {useContext, useEffect, useState} from 'react';
import countries from "../../common/countries.json";
import {
    cleanRegistrationSessionData,
    validateEmail,
    validatePhoneNumber,
    validateStandardInput
} from "../../common/util";
import {useForm} from "react-hook-form";
import keys from "../../common/keys";
import {createCardRequest, sendMonriCardRequest} from "../../account/account.service";
import {AuthorizationContext} from "../../account/context";
import {NavLink} from "react-router-dom";

const CardPersonalData = (props) => {
    const {isAuthorized, isLoaded, tokenData} = useContext(AuthorizationContext);
    const {register, handleSubmit, setValue, getValues} = useForm();
    const [nameValid, setNameValid] = useState(true);
    const [streetValid, setStreetValid] = useState(true);
    const [cityValid, setCityValid] = useState(true);
    const [zipCodeValid, setZipCodeValid] = useState(true);
    const [countryValid, setCountryValid] = useState(true);
    const [emailValid, setEmailValid] = useState(true);
    const [phoneNumberValid, setPhoneNumberValid] = useState(true);
    const [addCardStep, setAddCardStep] = useState(1);

    const [addCardCode, setAddCardCode] = useState(null);


    useEffect( () => {
        async function initializeForm(){
            try {
                const { data } = await createCardRequest();
                if (data.code) {
                    setAddCardCode(data.code);
                    setValue("name",tokenData.first_name+" "+tokenData.last_name);
                    setValue("email", tokenData.email);
                    if(tokenData.phone_number!="") setValue("phoneNumber", "+"+tokenData.phone_number);


                } else {
                    props.onError();
                }
            } catch (e) {
                props.onError();
            }
        };

        initializeForm();
    }, []);

    const onRegInputChangeValue = (event) => {
        switch (event.target.name){
            case "name": setNameValid(true); break;
            case "street": setStreetValid(true); break;
            case "city": setCityValid(true); break;
            case "zipCode": setZipCodeValid(true); break;
            case "country": setCountryValid(true); break;
            case "email": setEmailValid(true); break;
            case "phoneNumber": setPhoneNumberValid(true); break;
        }
    };

    const onPersonalDataSubmit = async (data) => {
        let valid = true;
        if(!validateStandardInput(data.name,30)) {
            setNameValid(false);
            valid = false;
        }
        if(!validateStandardInput(data.street,100)) {
            setStreetValid(false);
            valid = false;
        }
        if(!validateStandardInput(data.city,30)) {
            setCityValid(false);
            valid = false;
        }
        if(!validateStandardInput(data.zipCode,9)) {
            setZipCodeValid(false);
            valid = false;
        }
        if(!validateStandardInput(data.country,3)) {
            setCountryValid(false);
            valid = false;
        }
        if(!validateEmail(data.email) || !validateStandardInput(data.email,100)) {
            setEmailValid(false);
            valid = false;
        }
        if(!validatePhoneNumber(data.phoneNumber) || !validateStandardInput(data.phoneNumber,30)) {
            setPhoneNumberValid(false);
            valid = false;
        }

        if(valid) {
            try {
                const response = await sendMonriCardRequest({
                    code: addCardCode,
                    fullName: data.name,
                    address: data.street,
                    city: data.city,
                    zip: data.zipCode,
                    phone: data.phoneNumber,
                    country: data.country,
                    email: data.email
                });
                props.onFinish(response.data);
            } catch (e) {
                props.onError();
            }
        }
    }

    return (
        <div className="cardRegistrationBody">
            <div className="cardRegistrationTitle">Registracija nove platne kartice</div>
            <div className="cardRegistrationDescription">Da biste aktivirali ON TV uslugu, potrebno je da registrujete i sačuvate vašu platnu karticu koja će biti korištena za naplatu. Prilikom registracije biće vam rezervisano 0,10KM kako bi se provjerila validnost platne kartice. Ovaj iznos će vam biti vraćen na račun u roku od 30 dana, u zavisnosti od politika i pravila Vaše banke. </div>
            <div className="cardRegistrationDescription"><br/>Neophodno je da koristite aktivnu platnu karticu koja ima omogućena plaćanja putem Interneta. Postavke kartice za plaćanje putem interneta možete izvršiti korišćenjem aplikacije mobilnog bankarstva ili kontaktiranjem Vaše banke. Greška prilikom registracije kartice i plaćanja može da se desi ukoliko nije odobreno internet plaćanje karticom, ukoliko na kartici nema dovoljno sredstava, zbog pogrešno unesenih podataka uključujući i šifru koju Vaša banka šalje kao sigurnosnu potvrdu te u slučaju nepredviđenih tehničkih problema. Ukoliko su registracija kartice i plaćanje neuspješni probajte ponoviti proceduru ili nas kontaktirajte na <a href="mailto:podrska@ontv.ba">podrska©ontv.ba</a>. Mtel koristi <NavLink to={"/uslovi-placanja"} target={"_blank"}>najviše standarde sigurnosti mobilnog plaćanja</NavLink>.</div>
            <div className="cardRegistrationPersonalDataBody">
                <div className="cardRegistrationSubtitle">Podaci o vlasniku kartice</div>
                <form onSubmit={handleSubmit(onPersonalDataSubmit)} noValidate="novalidate" key="personalDataForm">
                    <div className="smallInputWrapper">
                        <label>Ime i prezime</label>
                        <input {...register("name")} className="full" type="text" name="name" onChange={onRegInputChangeValue} required/>
                    </div>
                    <div className={"validationErrorMessage marginTopSpaced"+(!nameValid ? " invalid" : "")}><i className="fa fa-warning"></i> Molimo unesite ispravno ime i prezime</div>
                    <div className="smallInputWrapper">
                        <label>Ulica</label>
                        <input {...register("street")} className="full" type="text" name="street" onChange={onRegInputChangeValue} required/>
                    </div>
                    <div className={"validationErrorMessage marginTopSpaced"+(!streetValid ? " invalid" : "")}><i className="fa fa-warning"></i> Molimo unesite ispravnu ulicu</div>
                    <div className="smallInputWrapper">
                        <label>Grad</label>
                        <input {...register("city")} className="full" type="text" name="city" onChange={onRegInputChangeValue} required/>
                    </div>
                    <div className={"validationErrorMessage marginTopSpaced"+(!cityValid ? " invalid" : "")}><i className="fa fa-warning"></i> Molimo unesite ispravan grad</div>
                    <div className="smallInputWrapper">
                        <label>Poštanski broj</label>
                        <input {...register("zipCode")} className="full" type="text" name="zipCode" onChange={onRegInputChangeValue} required/>
                    </div>
                    <div className={"validationErrorMessage marginTopSpaced"+(!zipCodeValid ? " invalid" : "")}><i className="fa fa-warning"></i> Molimo unesite ispravan poštanski broj</div>
                    <div className="smallInputWrapper">
                        <label>Država</label>
                        <select {...register("country")} className="full" name="country" value="BA" onChange={onRegInputChangeValue}>
                            {countries.map(country => <option key={country.key} value={country.key}>{country.name}</option>)}
                        </select>
                    </div>
                    <div className={"validationErrorMessage marginTopSpaced"+(!countryValid ? " invalid" : "")}><i className="fa fa-warning"></i> Molimo unesite ispravnu državu</div>
                    <div className="smallInputWrapper">
                        <label>E-mail</label>
                        <input {...register("email")} className="full" type="text" name="email" onChange={onRegInputChangeValue} required/>
                    </div>
                    <div className={"validationErrorMessage marginTopSpaced"+(!emailValid ? " invalid" : "")}><i className="fa fa-warning"></i> Molimo unesite ispravan e-mail</div>
                    <div className="smallInputWrapper">
                        <label>Broj telefona</label>
                        <input {...register("phoneNumber")} className="full" type="text" name="phoneNumber" onChange={onRegInputChangeValue} required/>
                    </div>
                    <div className={"validationErrorMessage marginTopSpaced"+(!phoneNumberValid ? " invalid" : "")}><i className="fa fa-warning"></i> Molimo unesite ispravan broj telefona</div>

                    <div className="centerWrapper">
                        <input className={"mainActionButton spacer center"} type="submit"
                               value={"Nastavi"}/>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default CardPersonalData;