import React from 'react';

const PageNotFound = () => {
    return (
        <div className="emptyPage">
            <div className="notFoundTitle">404</div>
            <div className="notFoundSubtitle">Stranica nije pronađena</div>
        </div>
    );
};

export default PageNotFound;