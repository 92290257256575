import React, {useState, useContext, useEffect} from 'react';
import {useAuthorizationStateValue} from "../../../account/context";
import TwoLineLink from "../../../components/inputs/two-line-link";
import {useForm} from "react-hook-form";
import {NavLink, useNavigate} from "react-router-dom";
import keys from "../../../common/keys";
import {approveEmail, approveSMS, resendEmail, resendSMS, startRegistration} from "../../../account/account.service";
import {getBrowserName} from "../../../common/util";

const RegistracijaBrojTelefonaPotvrda= (props) => {
    const {register, handleSubmit, setValue, getValues} = useForm();
    const [inputInvalid, setInputInvalid] = useState(true);
    const [isLoading,setLoading] = useState(false);
    const [invalidSubmit, setInvalidSubmit] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const [resendCodeMessage, setResendCodeMessage] = useState("Pošalji kod ponovo");
    const [resendCodeMessageBlocked, setResendCodeMessageBlocked] = useState(false);

    const {isAuthorized, tokenData} = useAuthorizationStateValue();

    let navigate = useNavigate();

    useEffect(() => {
        return async () => {
            let regStep = await sessionStorage.getItem(keys.MTEL_ID_REG_STEP);
            if(!regStep || (regStep!="2"&& regStep!="3")) {
                navigate('/kreiraj-nalog');
            }else {
                sessionStorage.setItem(keys.MTEL_ID_REG_STEP,"3");
            }
        };
    }, []);

    const onInputChangeValue = (event) => {
        setInvalidSubmit(false);
        setValue(event.target.name, event.target.value);
        if (getValues("activationCode").length > 0) setInputInvalid(false);
        else setInputInvalid(true);
    };

    const onSubmit = async (data) => {
        if (inputInvalid) return;
        if(isLoading) return;

        setLoading(true);

        try {
            const response = await approveSMS({
                uuid: sessionStorage.getItem(keys.MTEL_ID_REG_GUID),
                otp: data.activationCode
            });
            setLoading(false);
            navigate('/kreiraj-nalog/kreiraj-lozinku'); //TODO WS CODE VALID
        }catch (e){
            setErrorMessage("Unijeli ste neispravan aktivacioni kod."); //TODO ADD ATTEMPT LIMIT
            setInvalidSubmit(true);
            setLoading(false);
        }
    }

    const resendCode = async () => {
        if(resendCodeMessageBlocked) return;

        try {
            setResendCodeMessageBlocked(true);
            await resendSMS({
                uuid: sessionStorage.getItem(keys.MTEL_ID_REG_GUID),
                osType:1
            });
            setResendCodeMessage("Novi kod je uspješno poslat...");


        } catch (e) {
            setResendCodeMessage("Greška prilikom slanja novog koda...");

        }

        setTimeout(function (){
            setResendCodeMessageBlocked(false);
            setResendCodeMessage("Pošalji kod ponovo");
        },5000);
    }

    return (
        <div id="registerPagePhoneNumber" className="lightContent center">
            <div className="wizardCenterArea">
                <div className="mainTitle spacer spacerUp">Registruj se <span className="mainTileAdditional">> Potvrdi broj telefona</span></div>
                <div className="standardText">Na broj telefona <b>+{sessionStorage.getItem(keys.MTEL_ID_REG_USERNAME)}</b> smo poslali SMS sa aktivacionim kodom koji je potrebno unijeti u nastavku:
                </div>
                <form onSubmit={handleSubmit(onSubmit)} noValidate="novalidate">
                    <div className="standardInputWrapper">
                        <label>Aktivacioni kod</label>
                        <input {...register("activationCode")} className="full" type="number" name="activationCode" onChange={onInputChangeValue} required/><i className="fa fa-hashtag fa-lg"></i>
                    </div>
                    <div className={"validationErrorMessage"+(invalidSubmit ? " invalid" : "")}><i className="fa fa-warning"></i> {errorMessage}</div>
                    <div className="centerWrapper">
                        <a onClick={resendCode} className="actionLink">{resendCodeMessage}</a>
                    </div>
                    <div className="centerWrapper">
                        <input className={"mainActionButton center" + (inputInvalid ? " invalid" : "")+(isLoading ? " loading" : "")} type="submit"
                           value={"Nastavi"}/>
                    </div>
                </form>
            </div>

        </div>
    );

}

export default RegistracijaBrojTelefonaPotvrda;