import { useNavigate } from "react-router-dom";
import React, {useEffect} from "react";
import {deactivateAccount, fetchAccountInfo, reactivateAccountWithoutPayment} from "../../../account/account.service";

const ReaktivirajOntvBezPlacanja = () => {

    let navigate = useNavigate();

    useEffect(() => {
        async function fetchAccountData() {
            try{
                let info = await fetchAccountInfo();
                if(!info.data || info.data.status!="DEACTIVATED")
                    navigate("/nalog");
                else{
                    let npo = new Date(info.data.nextPaymentOn);
                    if(npo<=new Date()) navigate("/nalog");
                }
            }catch (e){
                navigate("/nalog");
            }
        };
        fetchAccountData();
    }, []);

    const deactivate = async (data) => {
        const result = await reactivateAccountWithoutPayment();
        navigate('/nalog/reaktiviraj-ontv/uspjesno');
    }

    return (
        <div id="registerPagePhoneNumber" className="lightContent center">
            <div className="wizardCenterArea">
                <div className="mainTitle spacer spacerUp">Reaktiviraj ON TV</div>
                <div className="standardText">
                    Da li sigurno želiš da reaktiviraš ON TV uslugu?
                </div>
                <div className="centerWrapper">
                    <button className="mainActionButton center" type="submit" onClick={deactivate}>Potvrdi reaktivaciju</button>
                </div>
            </div>

        </div>
    )
}

export default ReaktivirajOntvBezPlacanja;