import { useNavigate } from "react-router-dom";
import React from "react";

const PromjenaLozinkeUspjesna = () => {

    let navigate = useNavigate();

    const onSubmit = async (data) => {
        console.log(data);
        navigate('/nalog');
    }

    return (
        <div id="registerPagePhoneNumber" className="lightContent center">
            <div className="wizardCenterArea">
                <div className="mainTitle spacer spacerUp">Lozinka uspješno promijenjena!</div>
                <div className="centerWrapper">
                    <div className="standardText">Sada možeš da se vratiš nazad na podešavanja naloga.
                    </div>
                    <button className="mainActionButton center" type="submit" onClick={onSubmit}>Podešavanja naloga</button>
                </div>
            </div>

        </div>
    )
}

export default PromjenaLozinkeUspjesna;