import React, {useEffect, useState} from 'react';
import parse from "html-react-parser";
import DocumentTitle from "react-document-title";

const Faq = () => {
    const [faq, setFaq] = useState([]);

    useEffect(() => {
        fetch(`/resources/faq.json`)
            .then(res => res.json()).then((response)=>{
            setFaq(response);
        });
    }, []);

    return (
        <DocumentTitle title='ON TV > Česta pitanja'>
            <div className="lightContent center">
                <div className="fullTextArea">
                    <div className="mainTitle spacer spacerUp">Česta pitanja</div>
                    <div className="termsContent">
                    {faq.map(function (o) {
                        return (
                            <div className="singleFaq">
                                <div className="faqQ">{o.q}</div>
                                <div className="faqA">{parse(o.a)}</div>
                            </div>
                        );
                    })}
                    </div>
                </div>
            </div>
        </DocumentTitle>
    );
};

export default Faq;