import React, {useEffect, useState} from 'react';
import {NavLink, useNavigate} from "react-router-dom";
import parse from 'html-react-parser';
import {fetchAccountInfo, fetchPlanList} from "../account/account.service";
import DocumentTitle from "react-document-title";
import {isPromoPeriodActive, getPromoPeriodTitle} from "../common/plan-util";

const Paketi = () => {

    let navigate = useNavigate();
    const [planData, setPlanData] = useState({});
    const [planList, setPlanList] = useState([]);
    const [accountInfo, setAccountInfo] = useState(null);

    const subscribeToPlan = async (e) => {

        switch (e.target.id) {
            case "subscribe-starton":
                navigate('/pretplati-se?paket=starton');
                break;
            case "subscribe-maxon":
                navigate('/pretplati-se?paket=maxon');
                break;
        }

        console.log(e.target.id);
    }

    useEffect(() => {

        async function planInfo(){

            const sitePlanDataResponse = await fetch(`/resources/planData-v8.json`);
            const sitePlanData = await sitePlanDataResponse.json();

            const backendPlanDataResponse = await fetchPlanList();
            const backendPlanData = backendPlanDataResponse.data;

            backendPlanData.forEach(el => {
                if(sitePlanData.plans[el.code]) sitePlanData.plans[el.code].backendData = el;
            });

            setPlanList(sitePlanData.list);
            setPlanData(sitePlanData.plans);
        }

        planInfo();
    }, []);

    useEffect(() => {
        async function accountInfo() {
            try {
                let info = await fetchAccountInfo();
                setAccountInfo(info.data);
            } catch (ex) {
                console.log(ex)
            }
        };
        accountInfo();
    }, []);

    const ActionButton = (props) => {

        if (accountInfo && accountInfo.status!="EXPIRED") {
            if (accountInfo.plan.code == props.obj) return <div className="packageActivated">PAKET AKTIVIRAN</div>;
            else return "";
        } else {
            return <button id={"subscribe-" + props.obj} onClick={subscribeToPlan} type="submit"
                           className="btnPackage">PRETPLATI SE</button>;
        }
    }

    return (
        <DocumentTitle title='ON TV > Paketi'>
            <div className="iconContainer">
                <div className="tvChannelBackground">
                    <div className="subheaderMain">
                        <div className="promoTitle">
                            <div className="promoTitleTop">Odaberi</div>
                            <div className="promoTitleMiddle"><span className="red">ON TV</span> paket</div>
                            <div className="promoTitleBottom">po svojoj mjeri</div>
                            <div className="promoTitleDescription">Izaberi StartON ili MaxON paket sa bogatom ponudom TV
                                kanala i videoteka i uživaj u nakvalitetnijim TV sadržajima. I to sve bez ugovorne
                                obaveze, jer ON TV uslugu možeš deaktivirati kad god poželiš.
                            </div>
                        </div>
                    </div>
                </div>
                <div className="contentMain plansContentMain">
                    {planList.map(function (object, i) {
                        return (
                            <div className="packageCard">
                                <div className="package-col">
                                    <div className="packageTypes">
                                        <p>{planData[object].name}</p>
                                    </div>
                                    {isPromoPeriodActive(planData[object]) ? (
                                        <div className="ribbon">
                                            <p><b>{getPromoPeriodTitle(planData[object])}</b></p>
                                        </div>
                                    ) : ""
                                    }
                                    <div className="list">
                                        <div className="list-item">
                                            <i className="fa fa-angle-right"></i>
                                            <span>{planData[object].numChannels}</span> TV kanala <NavLink
                                            to={"/tv-kanali?paket=" + object} target='_blank' className='goToTv'>Lista
                                            kanala</NavLink>
                                        </div>
                                        {planData[object].additionalServices.map(function (as, j) {
                                            return (
                                                <div className="list-item">
                                                    <i className="fa fa-plus"></i><b>{as.name}</b> {as.type}
                                                </div>
                                            )
                                        })}
                                        <div className="list-text">
                                            {parse(planData[object].multiscreenDescription)}
                                        </div>
                                        <div className="planSpotlight">
                                            {parse(planData[object].planSpotlight)}
                                        </div>
                                    </div>
                                    <div className="price">
                                        <div className="promoPriceNow">
                                            <div><b>{planData[object].promoPrice}</b> KM</div>
                                        </div>
                                        <div className="promoPriceUsual">
                                            <div><b>{planData[object].price}</b> KM</div>
                                        </div>
                                        <div>mjesečno sa PDV-om u promo periodu do 31.10.</div>
                                    </div>
                                </div>
                                <ActionButton obj={object}/>
                            </div>
                        )
                    })}
                </div>
            </div>
        </DocumentTitle>
    );
};

export default Paketi;