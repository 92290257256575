import { useNavigate } from "react-router-dom";
import React from "react";

const ReaktivirajOntvUspjesno = () => {

    let navigate = useNavigate();

    const onSubmit = async (data) => {
        console.log(data);
        navigate('/nalog');
    }

    return (
        <div id="registerPagePhoneNumber" className="lightContent center">
            <div className="wizardCenterArea">
                <div className="mainTitle spacer spacerUp">ON TV usluga je uspješno reaktivirana</div>
                <div className="standardText">
                    Hvala na ponovonoj reaktivaciji ON TV usluge. Nadamo se da ćeš uživati i dalje u najboljim TV sadržajima.
                </div>
                <div className="centerWrapper">
                    <button className="mainActionButton center" type="submit" onClick={onSubmit}>Podešavanja naloga</button>
                </div>
            </div>

        </div>
    )
}

export default ReaktivirajOntvUspjesno;