import {
    changePassword,
    changePin,
    checkPin,
    fetchAccountInfo,
    fetchUserCreditCards
} from "../../../account/account.service";
import {useState} from "react";

const useAccount = () => {

    const [accountInfo, setAccountInfo] = useState();
    const [userCreditCards, setUserCreditCards] = useState([]);
    const [primaryCreditCard, setPrimaryCreditCard] = useState();

    async function getAccountInfo() {
        try {
            let info = await fetchAccountInfo();
            setAccountInfo(info.data);
        } catch (ex) {
            console.log(ex)
        }
    }

    async function getUserCreditCards() {
        try {
            let response = await fetchUserCreditCards();
            setUserCreditCards(response.data);
            console.log(response.data);
            setPrimaryCreditCard(response.data.find((creditCard) => creditCard.primaryCard === true));
        } catch (ex){
            console.log(ex);
        }
    }

    async function checkUserPin(pin) {
        try {
            let response = await checkPin(pin);
            if (response.status === 200) {
                return true;
            }
        } catch (ex) {
            console.log(ex);
            return false;
        }
    }

    async function changeUserPin(payload) {
        try {
            let response = await changePin(payload);
            if (response.status === 200) return true;
        } catch (ex) {
            console.log(ex);
            return false;
        }

    }

    async function changeUserPassword(payload) {
        return await changePassword(payload);
    }

    return {
        accountInfo,
        getAccountInfo,
        userCreditCards,
        primaryCreditCard,
        getUserCreditCards,
        checkUserPin,
        changeUserPin,
        changeUserPassword
    }
}

export default useAccount;