export default {
  UNAUTHORIZED: 401,
  OK: 200,
  INTERNAL_SERVER_ERROR: 500,
  NOT_FOUND: 404,
  CONFLICT: 409,
  PAYMENT_REQUIRED: 402,
  BAD_REQUEST: 400,
  UNPROCESSABLE_ENTITY: 422,
  SERVICE_UNAVAILABLE: 503,
  NOT_ACCEPTABLE: 406,
  BAD_GATEWAY: 502,
};
