import React from 'react';
import DocumentTitle from "react-document-title";
import {NavLink} from "react-router-dom";
import parse from "html-react-parser";

const Videoteke = () => {
    return (
        <DocumentTitle title='ON TV > Videoteke'>
            <div className="iconContainer">
                <div className="tvChannelBackground">
                    <div className="subheaderMain">
                        <div className="promoTitle">
                            <div className="promoTitleTop">ON TV</div>
                            <div className="promoTitleMiddle"><span className="red">Video</span>teke</div>
                            <div className="promoTitleDescription">Vrijeme je za pravo bioskopsko iskustvo na velikim ekranima uz ONTV aplikaciju za Android TV. Uz to, svoju omiljenu videoteku možeš nositi sa sobom i uživati uz sjajne sadržaje i putem mobilnih uređaja.
                            </div>
                        </div>
                    </div>
                </div>
                <div className="vodMain">
                    <div className="vodSectionContainer superstar">
                        <div className="vodSectionTitle"><h1>SUPERSTAR</h1></div>
                        <div className="vodSectionSubtitle">Uživaj u neprestanoj akciji, neizvjesnoj drami i spontanom humoru uz <b>SUPERSTAR</b> videoteku! Ne propusti hit serije, potpuno besplatno uz Max ON paket.</div>
                    </div>
                    <div className="vodSectionContainer epicDrama">
                        <div className="vodSectionTitle"><h1>EPIC DRAMA</h1></div>
                        <div className="vodSectionSubtitle">Bogat sadržaj različitih dramskih tema: kriminalistički, klasična književnost, politika i moć, istorijski, ratni, akciono-avanturistički i mnogi drugi očekuju vas u okviru <b>Epic Drama</b> videoteke.</div>
                    </div>
                    <div className="vodSectionContainer viasatWorld">
                        <div className="vodSectionTitle"><h1>VIASAT WORLD</h1></div>
                        <div className="vodSectionSubtitle"><b>Viasat World</b> videoteka pruža izbor iz programa Viasat Explore, Viasat Nature i Viasat History kanala donoseći bezvremensku zabavu iz stvarnog životu, oživljava istoriju i priča priče iz same prirode.</div>
                    </div>
                    <div className="vodSectionContainer axnNow">
                        <div className="vodSectionTitle"><h1>AXN NOW</h1></div>
                        <div className="vodSectionSubtitle">Gledaj najnovije serije iz bogate <b>AXN Now</b> ponude, hiljade minuta filmskog sadržaja potpuno <b>besplatno</b> u okviru Start ON i Max ON paketa.</div>
                    </div>
                    {/*<div className="vodSectionContainer nickPlus">
                        <div className="vodSectionTitle"><h1>Nick+</h1></div>
                        <div className="vodSectionSubtitle">Ekskluzivno na ONTV od <b>1. septembra</b></div>
                    </div>*/}
                </div>
            </div>
        </DocumentTitle>
    );
};

export default Videoteke;