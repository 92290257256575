import React from 'react';
import { NavLink, useLocation  } from "react-router-dom";
import {useAuthorizationStateValue} from "../account/context";

const opaqueHeaderOn = [
    "/nalog",
    "/kreiraj-nalog",
    "/pretplati-se",
    "/pretplata-uspjesna",
    "/pretplata-greska",
    "/potvrdi-pretplatu",
    "/zaboravljena-lozinka",
    "/uslovi",
    "/privacy",
    "/faq"
]

const Header = (params) => {
    const {isAuthorized, tokenData} = useAuthorizationStateValue();
    let location = useLocation();

    const matchHeader = (path) => {
        var retVal = false;
        opaqueHeaderOn.forEach(elem => {
            if(path.startsWith(elem)) {
                retVal=true;
                return;
            }
        });

        return retVal;
    };

    const handleClick = (event) => {
        if(document.getElementById("hi").checked){
            document.getElementById("header").classList.add("expanded");
        }else document.getElementById("header").classList.remove("expanded");
    };

    const dismissMenu = (event) => {
        if(document.getElementById("header").classList.contains("expanded") && document.getElementById("hi").checked){
            document.getElementById("hi").checked = false;
            document.getElementById("header").classList.remove("expanded");
        }
    };

    return (
        <div id="header" className={"header"+(matchHeader(location.pathname)?" headerOpaque":"")}>
            <div className="menuButton">
                <input type="checkbox" onClick={handleClick} id="hi" />
                    <label className="menu" htmlFor="hi">
                        <div className="bar"></div>
                        <div className="bar"></div>
                        <div className="bar"></div>
                    </label>
            </div>
            <div id="headerOverlay" onClick={dismissMenu} className="headerOverlay">
                <NavLink to="/" className="homeLogo">Home</NavLink>
                <div className="mainLinks">
                    <NavLink to="/paketi" className="navLink">Paketi</NavLink>
                    <NavLink to="/tv-kanali" className="navLink">TV kanali</NavLink>
                    <NavLink to="/videoteke" className="navLink">Videoteke</NavLink>
                </div>
                <div className="actionLinks">
                    <NavLink to="/nalog" className="navLink"><i className="fa fa-user-o"></i>{isAuthorized?(tokenData.first_name+" "+tokenData.last_name):"ON TV nalog"}</NavLink>
                </div>
            </div>
        </div>
    );
};

export default Header;