import {formatDateWithoutTime} from "./util";

export const getActivePromo = (plan) => {
    let promo = null;
    if(plan.backendData && plan.backendData.promos && plan.backendData.promos.length>0){
        plan.backendData.promos.forEach(function (pr){
            if(pr.status=="ACTIVE"){
                if(pr.promoType=="DURATION_BASED") {
                    promo = pr;
                }else if(new Date(pr.durationUntil)>new Date()) promo=pr;
            }
        })
    }

    return promo;
}

export const isPromoPeriodActive = (plan) => {
    let promo = getActivePromo(plan);
    console.log(promo);
    if(getActivePromo(plan)) return true;
    return false;
}

export const getPromoPeriodTitle = (plan) => {
    var promo = getActivePromo(plan);
    if(promo){
        if(promo.promoType=="TIME_UNTIL_BASED"){
            var dateTo = new Date(promo.durationUntil);
            return "Besplatan promo period do "+formatDateWithoutTime(dateTo)+"";
        }else return "Besplatan promo period "+promo.durationDays+" dana!";
    }
}