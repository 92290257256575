import React, {useState, useContext, useEffect} from 'react';
import {useAuthorizationStateValue} from "../../../account/context";
import TwoLineLink from "../../../components/inputs/two-line-link";
import {useForm} from "react-hook-form";
import {NavLink, useNavigate} from "react-router-dom";
import keys from "../../../common/keys";
import {approveEmail} from "../../../account/account.service";

const RegistracijaKreirajLozinku= (props) => {
    const {register, handleSubmit, setValue, getValues} = useForm();
    const [inputInvalid, setInputInvalid] = useState(true);
    const [isLoading,setLoading] = useState(false);
    const [invalidSubmit, setInvalidSubmit] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const {isAuthorized, tokenData} = useAuthorizationStateValue();

    let navigate = useNavigate();

    useEffect(() => {
        return async () => {
            let regStep = await sessionStorage.getItem(keys.MTEL_ID_REG_STEP);
            if(!regStep || (regStep!="3"&& regStep!="4")) {
                navigate('/kreiraj-nalog');
            }
            sessionStorage.setItem(keys.MTEL_ID_REG_STEP,"4");
        };
    }, []);

    const onInputChangeValue = (event) => {
        setInvalidSubmit(false);
        setValue(event.target.name, event.target.value);
        if (getValues("password").length > 0 && getValues("passwordConfirm").length) setInputInvalid(false);
        else setInputInvalid(true);
    };

    const validatePassword = (pass) => {
        if(pass.trim().length<8) return false;
        if(pass.trim().length>32) return false;
        if(!pass.trim().match(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#!@$%'()\*\]^\[&+.?"\\,/:;<>^_`{|}-]).{8,}$/)) return false;
        return true;
    };

    const onSubmit = async (data) => {
        if (inputInvalid) return;
        if(isLoading) return;

        setLoading(true);
        if(data.password.trim()!=data.passwordConfirm.trim()){
            setInvalidSubmit(true);
            setErrorMessage("Lozinke se ne poklapaju.");
            setLoading(false);
            return;
        }

        if(!validatePassword(data.password)){
            setInvalidSubmit(true);
            setErrorMessage("Lozinka mora sadržavati bar jedno veliko slovo, malo slovo, broj i specijalni karakter.");
            setLoading(false);
            return;
        }

        await sessionStorage.setItem(keys.MTEL_ID_REG_PASSWORD, data.password.trim());
        setLoading(false);
        navigate('/kreiraj-nalog/licni-podaci');
    }

    return (
        <div id="registerPagePhoneNumber" className="lightContent center">
            <div className="wizardCenterArea">
                <div className="mainTitle spacer spacerUp">Registruj se <span className="mainTileAdditional">> Kreiraj lozinku</span></div>
                <div className="standardText">Odaberi i kreiraj lozinku koju ćeš koristiti za prijavu na svoj nalog. Lozinka mora sadržavati minimalno 8 znakova uključujući bar jedno veliko slovo, malo slovo, broj i specijalni znak.
                </div>
                <form onSubmit={handleSubmit(onSubmit)} noValidate="novalidate">
                    <div className="standardInputWrapper">
                        <label>Lozinka</label>
                        <input {...register("password")} className="full" type="password" name="password" onChange={onInputChangeValue} required/><i className="fa fa-lock fa-lg"></i>
                    </div>
                    <div className="standardInputWrapper">
                        <label>Potvrdi lozinku</label>
                        <input {...register("passwordConfirm")} className="full" type="password" name="passwordConfirm" onChange={onInputChangeValue} required/><i className="fa fa-lock fa-lg"></i>
                    </div>
                    <div className={"validationErrorMessage"+(invalidSubmit ? " invalid" : "")}><i className="fa fa-warning"></i> {errorMessage}</div>
                    <div className="centerWrapper">
                        <input className={"mainActionButton center" + (inputInvalid ? " invalid" : "")+(isLoading ? " loading" : "")} type="submit"
                           value={"Nastavi"}/>
                    </div>
                </form>
            </div>

        </div>
    );

}

export default RegistracijaKreirajLozinku;