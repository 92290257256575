import React, {useState, useContext, useEffect} from 'react';
import {useAuthorizationStateValue} from "../../../account/context";
import TwoLineLink from "../../../components/inputs/two-line-link";
import {useForm} from "react-hook-form";
import {NavLink, useNavigate} from "react-router-dom";
import keys from "../../../common/keys";
import {cleanRegistrationSessionData} from "../../../common/util";

const RegistracijaUspjesna= (props) => {
    const [pinCode, setPinCode] = useState("");
    let navigate = useNavigate();

    useEffect( () => {
        async function fetchPinCode(){
            let pin = await sessionStorage.getItem(keys.MTEL_ID_REG_PIN);
            let regStep = await sessionStorage.getItem(keys.MTEL_ID_REG_STEP);
            if(!regStep || regStep!="5") {
                navigate('/nalog');
            }else {
                if (pinCode == "") setPinCode(pin);
                cleanRegistrationSessionData();
            }
        };

        fetchPinCode();
    }, []);


    return (
        <div id="registerPagePhoneNumber" className="lightContent center">
            <div className="wizardCenterArea">
                <div className="mainTitle spacer spacerUp">Čestitamo</div>
                <div className="standardText">
                    Tvoj nalog je uspješno kreiran. Da bi počeo da koristiš <b>ON TV</b> uslugu, odaberi paket i uživaj u najkvalitetnijim TV sadržajima.
                </div>
                <TwoLineLink link="/paketi" textUp="Odaberi" textDown="ONTV paket" />
                <div className="standardText spacerUp">
                    Da bi tvoj nalog bio dodatno zaštićen kreirali smo sljedeći <b>PIN kod</b> za autorizaciju:
                </div>
                <div className="infoTextArea spacer">{pinCode}</div>
                <div className="standardText">
                    Važno je da zapamtiš svoj PIN kod jer je isti potreban za autorizaciju određenih akcija u aplikacijama koje koristiš sa ovim nalogom. Ovaj PIN kod uvijek možeš promijeniti u podešavanjima.
                </div>
                <div className="centerWrapper">
                    <NavLink to="/nalog/promijeni-pin" className="actionLink">Promijeni PIN kod</NavLink>
                </div>
            </div>
        </div>
    );

}

export default RegistracijaUspjesna;