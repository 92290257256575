import React, {useContext, useEffect, useRef, useState} from 'react';
import {AuthorizationContext} from "../../../account/context";
import {Navigate, NavLink, useLocation, useNavigate} from "react-router-dom";
import {
    fetchAccountInfo,
    fetchPaymentData,
    fetchPaymentInfo,
    createCofPaymentRequest,
    fetchPaymentInfoForNewAccount, createNewAccountCofPaymentRequest
} from "../../../account/account.service";
import PaymentCard from "../../../components/payment-card";
import ConfirmDialog from "../../../components/confirm-dialog";
import Modal from "../../../components/modal";
import CardPersonalData from "../../../components/addCard/cardPersonalData";
import CardData from "../../../components/addCard/cardData";
import SavedCardSuccess from "../../../components/addCard/saveCardSuccess";
import SavedCardError from "../../../components/addCard/saveCardFailed";
import useAccount from "../hooks/account";
import {formatDate} from "../../../common/util";
import ConfirmCvv from "../../../components/addCard/confirmCvv";
import {useForm} from "react-hook-form";

const PotvrdiPretplatu = (props) => {

    const {register, handleSubmit, setValue, getValues} = useForm();
    const {setAuthorized, setTokenData, isAuthorized, isLoaded, tokenData} = useContext(AuthorizationContext);
    const [selectedCard, setSelectedCard] = useState(null);
    const {accountInfo, getAccountInfo} = useAccount();
    const [orderData, setOrderData] = useState(null);

    const [inputInvalid, setInputInvalid] = useState(true);
    const [paymentInputInvalid, setPaymentInputInvalid] = useState(true);
    const [cardInputInvalid, setCardInputInvalid] = useState(true);


    const [paymentInfo, setPaymentInfo] = useState(null);

    const [addCardStep, setAddCardStep] = useState(1);
    const [cardData, setCardData] = useState(null);

    const modal = useRef(null);
    const modalConfirmCvv = useRef(null);
    let navigate = useNavigate();
    let location = useLocation();

    const confirmDialog = useRef(null);

    useEffect(() => {
        if(isLoaded && !isAuthorized) navigate("/nalog");
    }, [isLoaded]);

    const onInputChangeValue = (event) => {
        if(event) setValue(event.target.name, !getValues(event.target.name));
        debugger;

        if (getValues("acceptTerms")==true && !paymentInputInvalid && !cardInputInvalid) setInputInvalid(false);
        else setInputInvalid(true);
    };

    const fetchCards = async () => {
        setCardInputInvalid(true);
        const {
            data: { creditCards, mbons },
        } = await fetchPaymentData();

        if(creditCards && creditCards.length>0){
            creditCards.forEach(function (card){
                if(card.primaryCard) {
                    setSelectedCard(card);
                    setCardInputInvalid(false);
                }
            });
        }
    }

    const fetchPayment = async () => {
        setPaymentInputInvalid(true);
        let planId = location.search.substr(6);
        const {
                data: paymentInfo
        } = await fetchPaymentInfoForNewAccount(planId);

        setPaymentInfo(paymentInfo);
        setPaymentInputInvalid(false);
    }

    useEffect(() => {
        fetchCards();
        fetchPayment();
    }, []);

    const nextStep = (cardData) => {
        setCardData(cardData);
        setAddCardStep(2);
    }

    const addNewCard = () => {
        setAddCardStep(1);
        modal.current.open();
    }

    const addNewCardSuccess = () => {
        fetchCards();
        setAddCardStep(3);
    }

    const addNewCardError = () => {
        setAddCardStep(4);
    }

    const finishedAdding = () => {
        fetchCards();
        modal.current.close();
    }

    const RenderStep = () => {
        switch(addCardStep){
            case 1: return <CardPersonalData onFinish={nextStep} onError={addNewCardError}/>;
            case 2: return <CardData cardData={cardData} onSuccess={addNewCardSuccess} onError={addNewCardError}/>;
            case 3: return <SavedCardSuccess onFinish={finishedAdding}/>
            case 4: return <SavedCardError modal={modal.current}/>
        }
    }

    const extendSubscription = async () => {
        if (inputInvalid) return;
        try {
            const {
                data: paymentProcessData,
            } = await createNewAccountCofPaymentRequest({
                creditCardId: selectedCard.id,
                amount: paymentInfo.ontvPlan.price,
                onTvPlanId: paymentInfo.ontvPlan.id
            });


            paymentProcessData.selectedCardNumber = selectedCard.cardNumber;
            paymentProcessData.planName = paymentInfo.ontvPlan.name;
            paymentProcessData.from = paymentInfo.periodStart;
            paymentProcessData.to = paymentInfo.periodEnd;

            setOrderData(paymentProcessData);
            modalConfirmCvv.current.open();

        }catch (ex){
            console.log(ex);
            navigate("/pretplata-greska");
        }
    }

    const onPaymentSuccess = () => {
        navigate("/pretplata-uspjesna");
    }

    const onPaymentError = () => {
        navigate("/pretplata-greska");
    }

    return (
        <div className="lightContent block">
            <div>
                <div className="actionHeader">
                    <div className="actionHeaderTitle">Potvrdi pretplatu</div>
                </div>
                <div className="accountSectionFirst">
                    <div className="profilePoint"><span className="profilePointName"><b>Paket: </b></span><span className="profilePointValue">{paymentInfo?paymentInfo.ontvPlan.name:""}</span></div>
                    <div className="profilePoint"><span className="profilePointName"><b>Period aktivacije: </b></span><span className="profilePointValue">{paymentInfo?(formatDate(new Date(paymentInfo.periodStart))+"-"+formatDate(new Date(paymentInfo.periodEnd))):""}</span></div>
                    <div className="profilePoint"><span className="profilePointName"><b>Cijena: </b></span><span className="profilePointValue">{paymentInfo?paymentInfo.ontvPlan.price+"KM sa PDV-om":""}</span></div>
                </div>
                <div className="accountSectionSecond">
                    <div id="confirmSubscriptionText" className="confirmSubscriptionText"><b>Način plaćanja:</b></div>
                    {selectedCard ?
                        <div className="cardSelectionArea"><span className={"cardLogo "+selectedCard.creditCardTypeCode}></span><span className="cardNumber">{selectedCard.cardNumber}</span></div>
                        :
                        <div className="newCardButton" onClick={addNewCard}>
                            Unesi platnu karticu
                        </div>
                    }
                </div>
                <div className="centerArea">

                    <div className="confirmAutomaticPaymentArea">
                        <input {...register("acceptTerms")} type="checkbox" onClick={onInputChangeValue} id="acceptTerms" name="acceptTerms"/>
                        <label htmlFor="acceptTerms"> Potvrdom plaćanja prihvatate sljedeće uslove vezano za automatsku periodičnu naplatu:</label>
                        <ul>
                            <li>Saglasni ste da naknada za korišćenje ONTV usluge bude automatski periodično naplaćena sa Vašeg računa za naredni pretplatni period.</li>
                            <li>Automatska naplata će biti realizovana na početku svakog pretplatnog perioda za taj period.</li>
                            <li>Ukoliko naplata pretplate ne uspije, biće pokušana nekoliko puta. Ukoliko naplata ne uspije, pretplatni period neće biti produžen a pružanje usluge će biti obustavljeno.</li>
                            <li>Otkazivanje pretplate će obustaviti dalju automatsku periodičnu pretplatu.</li>
                            <li>Pretplatu na uslugu možete otkazati u bilo kom trenutku izborom opcije “Deaktiviraj ONTV” na portalu usluge u meniju o korisničkim podacima koji se otvara klikom na nalog u gornjem desnom uglu portala.</li>
                            <li>Ukoliko deaktivirate uslugu, uslugu će te moći koristiti do datuma isteka plaćene pretplate.</li>
                            <li>Korišćenje ONTV usluge je definisano “Uslovima korišćenja” koji se mogu pronaći na linku u dnu ove stranice.</li>
                            <li>Uslovi plaćanja i politika privatnosti se mogu naći na linku u dnu ove stranice.</li>
                        </ul>
                    </div>

                    <button className={"mainActionButton"+(inputInvalid ? " invalid" : "")} onClick={extendSubscription}>Plati i potvrdi pretplatu</button>
                </div>
            </div>
            <Modal ref={modal} target="selectedPlanPrice">
                <RenderStep />
            </Modal>
            <Modal ref={modalConfirmCvv}>
                <ConfirmCvv orderData={orderData} onSuccess={onPaymentSuccess} onError={onPaymentError} />
            </Modal>
        </div>
    )
};

export default PotvrdiPretplatu;