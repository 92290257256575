import React, {useState, useContext, useEffect} from 'react';
import {useAuthorizationStateValue} from "../../../account/context";
import {NavLink} from "react-router-dom";
import TwoLineLink from "../../../components/inputs/two-line-link";
import keys from '../../../common/keys'
import {cleanRegistrationSessionData} from "../../../common/util";
import DocumentTitle from "react-document-title";

const KreirajNalog = (props) => {
    const {isAuthorized, tokenData} = useAuthorizationStateValue();

    useEffect(() => {
        return () => {
            cleanRegistrationSessionData();
            sessionStorage.setItem(keys.MTEL_ID_REG_STEP, "1");
        };
    }, []);


    return (
        <DocumentTitle title='ON TV > Kreiraj nalog'>
            <div id="registerPage" className="lightContent center">
                <div className="wizardCenterArea">
                    <div className="mainTitle spacer spacerUp">Registruj se</div>
                    <div className="standardText">ONTV nalog ti omogućava pristup najboljim sportskim i zabavnim
                        sadržajima putem mobilnog telefona ili velikih ekrana na Android TV-u, bilo kada i bilo gdje u BiH. Sačuvaj sve svoje podatke na
                        jednom mjestu.
                    </div>
                    <div className="standardText">Možeš se registrovati putem bilo kog broja mobilnog telefona ili
                        e-mail adrese, a ONTV možeš gledati isključivo na prostoru Bosne i Hercegovine.
                    </div>
                    <div className="standardText spacer">Svi tvoji podaci, uključujući i podatke o platnim karticama,
                        zaštićeni su u skladu sa najvišim sigurnosnim standardima.
                    </div>

                    <TwoLineLink link="/kreiraj-nalog/broj-telefona" textUp="Registracija preko"
                                 textDown="broja telefona"/>
                    <TwoLineLink link="/kreiraj-nalog/e-mail" textUp="Registracija preko" textDown="e-mail adrese"/>

                    <div className="secondaryTitle spacerUpFull"><b>Već imaš nalog?</b></div>
                    <div className="standardText spacer">Ukoliko već imaš nalog, ali je tvoja pretplata istekla, prijavi se i <b>obnovi ON TV pretplatu</b>.</div>
                    <TwoLineLink link="/nalog" textUp="" textDown="Prijavi se"/>
                </div>
            </div>
        </DocumentTitle>
    );

}

export default KreirajNalog;