import React from 'react';
import {useAuthorizationStateValue} from "../account/context";
import Login from './account/login';
import AccountHome from "./account/account-home";
import DocumentTitle from "react-document-title";

const Nalog = () => {
    const {isAuthorized, isLoaded, tokenData} = useAuthorizationStateValue();
    return (
        <DocumentTitle title='ON TV nalog'>
            <div>
                {isLoaded ? (isAuthorized ? (<AccountHome/>) : (<Login/>)) : <div className="lightContent"></div>}
            </div>
        </DocumentTitle>
    );
};

export default Nalog;