import React, {useEffect, useState} from 'react';
import {NavLink, useNavigate} from "react-router-dom";
import {useAuthorizationStateValue} from "../../../account/context";
import {useLocation} from "react-use";
import parse from "html-react-parser";
import {useForm} from "react-hook-form";

const PretplataUspjesna = (props) => {
    const {register, handleSubmit, setValue, getValues} = useForm();
    const [inputInvalid, setInputInvalid] = useState(true);
    const {isAuthorized, tokenData} = useAuthorizationStateValue();
    const [selectedPlan, setSelectedPlan] = useState({});
    const [selectedCard, setSelectedCard] = useState(null);

    let navigate = useNavigate();
    let location = useLocation();

    return (
        <div id="subscriptionConfirmationPage" className="lightContent">
            <div className="subscribeSuccessArea">
                <div className="mainTitleLeft">
                    <div className="mainTitle">Čestitamo!</div>
                    <div className="mainSubtitle">Tvoja <b>ON TV</b> pretplata je uspješno aktivirana.</div>
                </div>
                <div className="mainTitleLeft">
                    <div className="secondaryTitle">Kako da gledaš <b>ON TV</b>?</div>
                    <ol>
                            <li className="mainSubtitle">Preuzmi ON TV aplikaciju na svoj mobilni telefon, tablet ili AndroidTV uređaj</li>
                            <li className="mainSubtitle">Prijavi se u aplikaciji sa svojim nalogom</li>
                            <li className="mainSubtitle">Gledaj i uživaj!</li>
                    </ol>
                </div>
                <div className="confirmSubscriptionBadges">
                    <a href="https://play.google.com/store/apps/details?id=ba.ontv.app.androidtv" target="_blank" className="aboutBadge androidTv dark">Android TV</a>
                    <a href="https://play.google.com/store/apps/details?id=ba.ontv.app" target="_blank" className="aboutBadge playStore dark">Dostupno na Google PlayStore</a>
                    <a href="https://apps.apple.com/ba/app/on-tv-bih/id1632197904" target="_blank" className="aboutBadge appStore dark">Dostupno na Apple AppStore</a>
                    <a href="https://appgallery.huawei.com/app/C106636725" target="_blank" className="aboutBadge appGallery dark">Dostupno na Huawei AppGallery</a>
                </div>
                <NavLink className="backToAccount" to={"/nalog"}>&lt; Nazad na moj nalog</NavLink>
            </div>



        </div>
    )
};

export default PretplataUspjesna;