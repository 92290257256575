import React, {useContext, useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import useAccount from "../hooks/account";
import {useNavigate} from "react-router-dom";
import {AuthorizationContext} from "../../../account/context";

const PromijeniPin = () => {
    const {setAuthorized, setTokenData, isAuthorized, isLoaded, tokenData} = useContext(AuthorizationContext);
    const {register, handleSubmit, setValue, getValues} = useForm();
    const [inputInvalid, setInputInvalid] = useState(true);
    const [isLoading, setLoading] = useState(false);
    const [oldPinIncorrect, setOldPinIncorrect] = useState(false);
    const [nonMatchingPin, setNonMatchingPin] = useState(false);
    const [invalidSubmit, setInvalidSubmit] = useState(false);
    const { checkUserPin, changeUserPin } = useAccount();

    let navigate = useNavigate();

    useEffect(() => {
        if(isLoaded && !isAuthorized) navigate("/nalog");
    }, [isLoaded]);

    const onInputChangeValue = (event) => {
        setInvalidSubmit(false);
        setValue(event.target.name, event.target.value);

        if (event.target.value === "" ||
            !getValues("oldPin") ||
            !getValues("newPin") ||
            !getValues("newPinConfirmation"))
        {
            setInputInvalid(true);
        } else {
            setInputInvalid(false);
        }

        if(event.target.name  === "oldPin") {
            setOldPinIncorrect(false);
        }

        if (event.target.name === "newPinConfirmation" || event.target.name == "newPin") {
            if(
                getValues('newPin') && getValues('newPinConfirmation') &&
                getValues('newPin') !== getValues('newPinConfirmation'))
            {
                setNonMatchingPin(true);
            } else {
                setNonMatchingPin(false);
            }
        }
    };

    useEffect(() => {
        if (oldPinIncorrect || nonMatchingPin) {
            setInputInvalid(true);
        }
    }, [oldPinIncorrect, nonMatchingPin, getValues('newPinConfirmation'), getValues('newPin')])

    const onSubmit = async (data) => {
        setLoading(true);
        const oldPinOk = await checkUserPin(data.oldPin);

        if(oldPinOk) {
            try {
                const response = await changeUserPin({
                    oldPin: data.oldPin,
                    newPin: data.newPin
                });
                navigate('/nalog/promijeni-pin/uspjesno');
                setLoading(false);
            } catch (ex) {
                console.log(ex);
                setLoading(false);
            }


        } else {
            setOldPinIncorrect(true);
            setInvalidSubmit(true);
            setLoading(false);
        }

    }

    return <div className="lightContent center">
        <div className="wizardCenterArea">
            <div className="mainTitle spacer spacerUp">Promijeni PIN</div>
            <div className="standardText">
                Radi potvrđivanja identiteta prvo unesi svoj stari PIN kod.
                Nakon toga unesi novi PIN, a zatim ga potvrdi ponovnim upisivanjem u polje ispod.
            </div>
            <form noValidate onSubmit={handleSubmit(onSubmit)}>
                <div className="standardInputWrapper">
                    <label>Stari PIN</label>
                    <input
                        className="full"
                        pattern="[0-9]*"
                        inputmode="numeric"
                        type="password"
                        name='oldPin'
                        maxLength={4}
                        onChange={onInputChangeValue}
                        required
                    />
                    <i className="fa fa-lock fa-lg"></i>
                </div>
                <div className={"validationErrorMessage"+(oldPinIncorrect ? " invalid" : "")}><i className="fa fa-warning"></i> Uneseni PIN je netačan</div>
                <div className="standardInputWrapper">
                    <label>Novi PIN</label>
                    <input
                        className="full"
                        type="password"
                        name='newPin'
                        maxLength={4}
                        onChange={onInputChangeValue}
                        required
                    />
                    <i className="fa fa-lock fa-lg"></i>
                </div>
                <div className="standardInputWrapper">
                    <label>Potvrdi novi PIN</label>
                    <input
                        className="full"
                        type="password"
                        name="newPinConfirmation"
                        maxLength={4}
                        onChange={onInputChangeValue}
                        required
                    />
                    <i className="fa fa-lock fa-lg"></i>
                </div>
                <div className={"validationErrorMessage"+(nonMatchingPin ? " invalid" : "")}><i className="fa fa-warning"></i> Uneseni PIN-ovi se ne podudaraju</div>
                <div className="centerWrapper">
                    <input
                        className={
                            "mainActionButton center" +
                            (inputInvalid ? " invalid" : "") +
                            (isLoading ? " loading" : "")
                         }
                        type="submit"
                        value={"Promijeni PIN"}
                    />
                </div>
            </form>
        </div>
    </div>
}

export default PromijeniPin;