import React, {useContext, useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";
import {AuthorizationContext, useAuthorizationStateValue} from "../../account/context";
import {useLocation} from "react-use";
import {useForm} from "react-hook-form";
import {fetchInoviceData} from "../../account/account.service";
import {formatDate, formatDateWithoutTime, getAmountWithoutVatStr, getVatStr, getAmountStr} from "../../common/util";
import useAccount from "./hooks/account";

const Racun = (props) => {
    const {setAuthorized, setTokenData, isAuthorized, isLoaded, tokenData} = useContext(AuthorizationContext);
    const [inoviceData, setInoviceData] = useState(null);
    const { accountInfo, getAccountInfo} = useAccount();

    let navigate = useNavigate();
    let location = useLocation();

    useEffect(() => {
        if(isLoaded && !isAuthorized) navigate("/nalog");
        else if(isLoaded && isAuthorized){
            fetchData();
            getAccountInfo();
        }
    }, [isLoaded]);

    const fetchData = async () => {

        let no = location.search.substr(4);
        try{
            const {
                data: invoiceData
            } = await fetchInoviceData(no);
            setInoviceData(invoiceData);
            debugger;
            console.log(inoviceData);
        }catch (ex){
            navigate("/not-found")
        }
    }

    return (
        <div className="inoviceArea">
            <div className="invoiceHeader">
                <div className="inoviceHeaderLogo"><img src="/resources/images/mtel-logo.png" /> </div>
                <div className="invoiceHeaderCompany">
                    <b>Mtel a.d. Banja Luka</b><br />
                    <span className="inoviceSmaller">
                        Vuka Karadžića 2, 78000 Banja Luka, BiH<br />
                        <b>IB</b> 400964000002 - <b>MB</b> 1772902 - <b>BSR:U/I</b> 1-9317-00, Osnovni Sud Banja Luka<br/>
                        Info telefon: 0800 50 000<br/>
                        E-mail: podrska@ontv.ba
                    </span>
                </div>
            </div>
            <div>ID korisnika: <b>{accountInfo?accountInfo.accountNumber:null}</b></div>
            <div className="invoiceRecepient">Korisničko ime: <b>{tokenData?tokenData.device_username:null}</b></div>
            <div className="inoviceNumber">Račun br: <b>{inoviceData?inoviceData.orderNumber:null}</b></div>
            <div className="inoviceNumber">Vrijeme izdavanja: <b>{inoviceData?formatDate(new Date(inoviceData.updated)):null}</b></div>
            <div className="inoviceNumber">Plaćeno sa: <b>{inoviceData?inoviceData.creditCardNumber:null}</b></div>
            <div className="invoicePriceHeader">
                <div className="iph-col-1">Opis usluge</div>
                <div className="iph-col-2">Period računa</div>
                <div className="iph-col-3">Iznos sa PDV</div>
            </div>
            <div className="invoicePriceContent">
                <div className="iph-col-1">Pretplata na ONTV servis - {inoviceData?inoviceData.paymentData.name:null} paket</div>
                <div className="iph-col-2">{inoviceData?
                    (formatDateWithoutTime(new Date(inoviceData.periodStart))+" - "+
                    formatDateWithoutTime(new Date(inoviceData.periodEnd))):null
                }</div>
                <div className="iph-col-3">{getAmountStr(inoviceData?inoviceData.amount:0)}</div>
            </div>
            <div className="invoicePriceFooter">
                <div className="ipf-row">
                    <div className="ipf-name">Ukupno bez PDV-a:</div>
                    <div className="ipf-value">{getAmountWithoutVatStr(inoviceData?inoviceData.amount:0)}</div>
                </div>
                <div className="ipf-row">
                    <div className="ipf-name">PDV (17%):</div>
                    <div className="ipf-value">{getVatStr(inoviceData?inoviceData.amount:0)}</div>
                </div>
                <div className="ipf-row">
                    <div className="ipf-name"><b>Ukupno sa PDV-om:</b></div>
                    <div className="ipf-value"><b>{getAmountStr(inoviceData?inoviceData.amount:0)}</b></div>
                </div>
            </div>
            <div className="inoviceRemark">Reklamacija na iznos računa se podnosi lično do 30. u mjesecu za protekli mjesec.<br />
            Račun je kreiran elektronskim putem i punovažan je bez potpisa i pečata.</div>
        </div>
    )
};

export default Racun;