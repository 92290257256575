import React, { useCallback, useEffect, useState } from 'react';
import keys from "../common/keys";
import jwt_decode from 'jwt-decode';
import moment from 'moment';

const defaultState = {
    isAuthorized: false,
    tokenData: null,
}

const defaultActions = {
    setAuthorized: (payload) => {},
    setTokenData: (payload) => {},
};

export const AuthorizationContext = React.createContext({
    ...defaultState,
    ...defaultActions,
});
export const AuthorizationConsumer = AuthorizationContext.Consumer;


const AuthorizationProvider = (props) => {

    const [isAuthorized, setAuthorized] = useState(false);
    const [tokenData, setTokenData] = useState(null);
    const [isLoaded, setLoaded] = useState(false);

    const init = useCallback(async () => {

        const mtelIdToken = await localStorage.getItem(keys.MTEL_ID_TOKEN);
        if (mtelIdToken) {
            const decodedToken = jwt_decode(mtelIdToken);
            setTokenData(decodedToken);
            setAuthorized(true);
            //refresh will solve expiration of token
            /*if (moment(decodedToken.exp * 1000).isAfter(moment())) {
                setAuthorized(true);
            }*/
        } else {
            setAuthorized(false);
            setTokenData(null);
        }
    });

    useEffect(() => {
        init()
            .then(() => {
                setLoaded(true);
            })
            .catch(({ response }) => {
                //DONOTHING TODO
            });
        // eslint-disable-next-line
    }, []);

    const state = {
        isAuthorized,
        tokenData,
        isLoaded,
        setAuthorized,
        setTokenData
    };

    return <AuthorizationContext.Provider value={state}>{props.children}</AuthorizationContext.Provider>;
};

export const useAuthorizationStateValue = () => React.useContext(AuthorizationContext);
export default AuthorizationProvider;