import React, {useEffect, useRef, useState} from 'react';
import {config as getConfig} from '../../environments/environments';
import CardData from "./cardData";
import {formatDate} from "../../common/util";

const ConfirmCvv = (props) => {
    const monriRef = useRef();
    const [clientSecret, setClientSecret] = useState(null);
    const [cvvValid, setCvvValid] = useState(false);
    const [orderData, setOrderData] = useState(null);

    const cvvFirstEventCall = useRef(true);
    const [cvvComponent, setCvvComponent] = useState(null);

    useEffect(()=>{
        if (props.orderData && props.orderData.paymentSecret && props.orderData.paymentSecret!=clientSecret) {
            setClientSecret(props.orderData.paymentSecret);
            // eslint-disable-next-line no-undef
            let monri = Monri(getConfig().monriAuthenticityToken, {locale: 'sr'});
            let components = monri.components({"clientSecret": props.orderData.paymentSecret, "developmentMode":true});

            let cvvComponent = components.create('cvv', {
                style: {
                    base: {
                        fontSize: '14px',
                        color: '#504D4D',
                        fontFamily: 'Poppins,sans-serif',
                        textIndent: '6px',
                        backgroundColor: 'transparent',
                        padding: '0px 5px',
                        width: '100% !important'
                    },
                    label: {
                        base: {
                            textTransform: 'capitalize',
                            fontSize: '16px',
                            letterSpacing: '0',
                            backgroundColor: 'transparent',
                            margin: "0px 0px 15px 0px",
                            textIndent: '0',
                            fontWeight: '300'
                        }
                    },
                    input: {
                        base: {
                            padding: '20px',
                            borderRadius: '7px',
                            fontSize: '16px',
                            backgroundColor: 'white',
                            border: '2px solid transparent',
                            boxShadow: '2px 2px 10px rgba(0, 0, 0, 0.1)'
                        },
                        invalid: {
                            color: '#e63b47'
                        },
                        complete: {
                            color: '#548b54'
                        },
                        empty: {
                            color: '#e63b47'
                        }
                    }
                }
            });
            console.log(props.orderData);

            cvvComponent.addChangeListener('cvv', cvvChangeValidator);
            setCvvComponent(cvvComponent);

            cvvComponent.mount(monriRef.current.id);
            if(props.orderData) {
                cvvComponent.setActivePaymentMethod(props.orderData.panToken);
            }
        }
    });

    const cvvChangeValidator = (ev) => {
        if (!cvvFirstEventCall.current)
            setCvvValid(ev.valid);
        cvvFirstEventCall.current = false;
    }

    const onFinishClick = async () => {
        // eslint-disable-next-line no-undef
        let monri = Monri(getConfig().monriAuthenticityToken, {locale: 'sr'});

        let params = {
            address: props.orderData.card.address,
            fullName: props.orderData.card.fullName,
            city: props.orderData.card.city,
            zip: props.orderData.card.zip,
            phone: props.orderData.card.phone,
            country: props.orderData.card.country,
            email: props.orderData.card.email,
            orderInfo: props.orderData.orderInfo.orderInfo
        };

        monri.confirmPayment(cvvComponent, params).then((result) => {
            if (result.error) {
                console.log(result.error);
                props.onError();
            }else if(result.result.status=="declined") {
                console.log("ERROR");
                props.onError();
            }else{
                console.log("SUCCESS");
                props.onSuccess();
            }
        }).catch(error => {
            console.log(error);
            props.onError();
        });
    }

    return (
        <div className="cvvConfirmBody">
            <div className="cardRegistrationTitle">Potvrda plaćanja</div>
            <div>
                <div className="profilePoint"><span className="profilePointName"><b>Paket: </b></span><span className="profilePointValue">{props.orderData?props.orderData.planName:""}</span></div>
                <div className="profilePoint"><span className="profilePointName"><b>Period produženja: </b></span><span className="profilePointValue">{props.orderData?(formatDate(new Date(props.orderData.from))+"-"+formatDate(new Date(props.orderData.to))):""}</span></div>
                <div className="profilePoint"><span className="profilePointName"><b>Cijena: </b></span><span className="profilePointValue">{props.orderData?props.orderData.orderInfo.amount+"KM sa PDV-om":""}</span></div>
            </div>
            <div className="cvvDescription">Molimo unesite CVV/CVC kod sa poleđine vaše platne kartice {props.orderData?props.orderData.selectedCardNumber:""} kako biste potvrdili plaćanje.
            </div>
            <div className="cardRegistrationPersonalDataBody">
                <div ref={monriRef} id="monri-root" className="cvvMonriArea"></div>
                <div className="centerWrapper">
                    <button disabled={!cvvValid} className={"mainActionButton center"} onClick={onFinishClick}>Plati</button>
                </div>
            </div>
        </div>
    );
}

export default ConfirmCvv;