import React, {useEffect, useState} from 'react';
import {NavLink, useNavigate} from "react-router-dom";
import {useAuthorizationStateValue} from "../../../account/context";
import {useLocation} from "react-use";
import {useForm} from "react-hook-form";

const PlacanjeGreska = (props) => {
    const {register, handleSubmit, setValue, getValues} = useForm();
    const [inputInvalid, setInputInvalid] = useState(true);
    const {isAuthorized, tokenData} = useAuthorizationStateValue();
    const [selectedPlan, setSelectedPlan] = useState({});
    const [selectedCard, setSelectedCard] = useState(null);

    let navigate = useNavigate();
    let location = useLocation();

    return (
        <div id="subscriptionConfirmationPage" className="lightContent">
            <div className="subscribeSuccessArea">
                <div className="mainTitleLeft">
                    <div className="mainTitle">Dogodila se greška prilikom plaćanja!</div>
                    <div className="mainSubtitle"><br />Nismo uspjeli da produžimo tvoju ONTV pretplatu. <br />Pokušaj ponovo ili kontaktiraj našu podršku na <a href="mailto:podrska@ontv.ba" className="standardTextHref">podrska@ontv.ba</a></div>
                </div>
                <NavLink className="backToAccount" to={"/nalog"}>&lt; Nazad na moj nalog</NavLink>
            </div>



        </div>
    )
};

export default PlacanjeGreska;