/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect} from "react";
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import DocumentTitle from "react-document-title";
import AuthorizationProvider from "./account/context";

import Header from "./components/header";
import Footer from "./components/footer";

import Home from './pages/home';
import Paketi from './pages/paketi';
import TvKanali from './pages/tv-kanali';
import Videoteke from './pages/videoteke';
import Nalog from './pages/nalog';
import KreirajNalog from './pages/account/registration/kreiraj-nalog';
import ScrollToTop from "./components/backToTopButton";
import RegistracijaBrojTelefona from "./pages/account/registration/registracija-broj-telefona";
import RegistracijaEmail from "./pages/account/registration/registracija-email";
import RegistracijaEmailPotvrda from "./pages/account/registration/registracija-email-potvrda";
import RegistracijaKreirajLozinku from "./pages/account/registration/registracija-kreiraj-lozinku";
import RegistracijaLicniPodaci from "./pages/account/registration/registracija-licni-podaci";
import RegistracijaUspjesna from "./pages/account/registration/registracija-uspjesna";
import {checkAndSetDeviceId} from "./common/util";
import RegistracijaBrojTelefonaPotvrda from "./pages/account/registration/registracija-broj-telefona-potvrda";
import PretplatiSe from "./pages/account/subscription/pretplati-se";
import PretplataUspjesna from "./pages/account/subscription/pretplata-uspjesna";
import PretplataGreska from "./pages/account/subscription/pretplata-greska";
import MojeKartice from "./pages/account/profile-options/moje-kartice";

import ZaboravljenaLozinka from "./pages/account/forgotPassword/zaboravljena-lozinka";
import ResetovanjeZaboravljeneLozinke from "./pages/account/forgotPassword/resetovanje-zaboravljene-lozinke";
import ResetovanjeKodPotvrdaBrojTelefona from "./pages/account/forgotPassword/resetovanje-kod-potvrda-broj-telefona";
import ResetovanjeLozinkeUspjesno from "./pages/account/forgotPassword/resetovanje-lozinke-uspjesno";
import ResetovanjeKodPotvrdaEmail from "./pages/account/forgotPassword/resetovanje-kod-potvrda-email";
import PromijeniPin from "./pages/account/changePin/promijeni-pin";
import PromjenaPinaUspjesna from "./pages/account/changePin/promjena-pina-uspjesna";
import PromijeniLozinku from "./pages/account/changePassword/promijeni-lozinku";
import PromjenaLozinkeUspjesna from "./pages/account/changePassword/promjena-lozinke-uspjesna";
import PageNotFound from "./pages/page-not-found";
import DeaktivirajOntv from "./pages/account/deactivateAccount/deaktiviraj-ontv";
import DeaktivirajOntvUspjesno from "./pages/account/deactivateAccount/deaktiviraj-ontv-uspjesno";
import ReaktivirajOntvBezPlacanja from "./pages/account/deactivateAccount/reaktiviraj-ontv-bez-placanja";
import ReaktivirajOntvUspjesno from "./pages/account/deactivateAccount/reaktiviraj-ontv-uspjesno";
import Privacy from "./pages/privacy";
import UsloviPlacanja from "./pages/uslovi-placanja";
import UsloviKoriscenja from "./pages/uslovi-koriscenja";
import ProduziPretplatu from "./pages/account/profile-options/produzi-pretplatu";
import IstorijaUplata from "./pages/account/profile-options/istorija-uplata";
import PlacanjeUspjesno from "./pages/account/profile-options/placanje-uspjesno";
import PlacanjeGreska from "./pages/account/profile-options/placanje-greska";
import Racun from "./pages/account/racun";
import {useLocation} from "react-use";
import Faq from "./pages/faq";
import PotvrdiPretplatu from "./pages/account/subscription/potvrdi-pretplatu";

function App(props) {
    useEffect(() => {
        checkAndSetDeviceId();
    }, []);

    let location = useLocation();

    return (
        <AuthorizationProvider>
            <DocumentTitle title='ON TV'>
                <Router>
                    <ScrollToTop/>
                    {location.pathname.indexOf("/nalog/racun")!=0 ? <Header/>:null}
                    <Routes>
                        <Route exact path='/' exact element={<Home/>}/>
                        <Route path='/paketi' element={<Paketi/>}/>
                        <Route path='/tv-kanali' element={<TvKanali/>}/>
                        <Route path='/videoteke' element={<Videoteke/>}/>
                        <Route path='/nalog' element={<Nalog/>}/>
                        <Route path='/kreiraj-nalog' element={<KreirajNalog/>}/>
                        <Route path='/nalog/promijeni-pin' element={<PromijeniPin />} />
                        <Route path='/nalog/promijeni-pin/uspjesno' element={<PromjenaPinaUspjesna />} />
                        <Route path='/nalog/promijeni-lozinku' element={<PromijeniLozinku />} />
                        <Route path='/nalog/promijeni-lozinku/uspjesno' element={<PromjenaLozinkeUspjesna />} />

                        <Route path='/nalog/deaktiviraj-ontv' element={<DeaktivirajOntv />} />
                        <Route path='/nalog/deaktiviraj-ontv/uspjesno' element={<DeaktivirajOntvUspjesno />} />
                        <Route path='/nalog/reaktiviraj-ontv-bez-placanja' element={<ReaktivirajOntvBezPlacanja />} />
                        <Route path='/nalog/reaktiviraj-ontv/uspjesno' element={<ReaktivirajOntvUspjesno />} />

                        <Route path='/kreiraj-nalog/broj-telefona' element={<RegistracijaBrojTelefona />} />
                        <Route path='/kreiraj-nalog/e-mail' element={<RegistracijaEmail/>} />
                        <Route path='/kreiraj-nalog/potvrdi-e-mail' element={<RegistracijaEmailPotvrda/>} />
                        <Route path='/kreiraj-nalog/potvrdi-broj-telefona' element={<RegistracijaBrojTelefonaPotvrda/>} />
                        <Route path='/kreiraj-nalog/kreiraj-lozinku' element={<RegistracijaKreirajLozinku/>} />
                        <Route path='/kreiraj-nalog/licni-podaci' element={<RegistracijaLicniPodaci/>} />
                        <Route path='/kreiraj-nalog/registracija-uspjesna' element={<RegistracijaUspjesna/>} />
                        
                        <Route path='/zaboravljena-lozinka' element={<ZaboravljenaLozinka/>}/>
                        <Route path='/zaboravljena-lozinka/resetovanje-zaboravljene-lozinke'
                               element={<ResetovanjeZaboravljeneLozinke/>}/>
                        <Route path='/zaboravljena-lozinka/resetovanje-kod-potvrda-email'
                               element={<ResetovanjeKodPotvrdaEmail/>}/>
                        <Route path='/zaboravljena-lozinka/resetovanje-kod-potvrda-broj-telefona'
                               element={<ResetovanjeKodPotvrdaBrojTelefona/>}/>
                        <Route path='/zaboravljena-lozinka/resetovanje-lozinke-uspjesno'
                               element={<ResetovanjeLozinkeUspjesno/>}/>

                        <Route path='/pretplati-se' element={<PretplatiSe/>}/>
                        <Route path='/pretplata-uspjesna' element={<PretplataUspjesna/>}/>
                        <Route path='/pretplata-greska' element={<PretplataGreska/>}/>
                        <Route path='/potvrdi-pretplatu' element={<PotvrdiPretplatu/>}/>

                        <Route path='/nalog/moje-kartice' element={<MojeKartice/>}/>
                        
                        <Route path='/nalog/istorija-uplata' element={<IstorijaUplata/>}/>

                        <Route path='/faq' element={<Faq/>}/>
                        <Route path='/uslovi-placanja' element={<UsloviPlacanja/>}/>
                        <Route path='/uslovi-koriscenja' element={<UsloviKoriscenja/>}/>
                        <Route path='/privacy-policy/' element={<Privacy/>}/>

                        <Route path='/nalog/placanje-uspjesno'
                               element={<PlacanjeUspjesno/>}/>

                        <Route path='/nalog/placanje-greska'
                               element={<PlacanjeGreska/>}/>

                        <Route path='/nalog/racun' element={<Racun/>}/>

                        <Route path="*" element={<PageNotFound />} />
                    </Routes>
                    {location.pathname.indexOf("/nalog/racun")!=0 ? <Footer/>:null}
                </Router>

            </DocumentTitle>
        </AuthorizationProvider>
    );
}

export default App;
