export default {
    MTEL_ID_DEVICE_ID: 'mtel_id_device_id',
    MTEL_ID_TOKEN: 'mtel_id_token',
    MTEL_ID_REG_STEP: 'mtel_id_reg_step',
    MTEL_ID_REG_USERNAME: 'mtel_id_reg_username',
    MTEL_ID_REG_PASSWORD: 'mtel_id_reg_password',
    MTEL_ID_REG_GUID: 'mtel_id_reg_guid',
    MTEL_ID_REG_PIN: "mtel_id_reg_pin",

    MTEL_ID_FORGOT_PASSWORD_STEP: "mtel_id_forgot_password_step",
    MTEL_ID_FORGOT_PASSWORD_REG_TYPE: "mtel_id_forgot_password_reg_type",
    MTEL_ID_FORGOT_PASSWORD_USERNAME: "mtel_id_forgot_password_username",
    MTEL_ID_FORGOT_PASSWORD_USER_ID:"mtel_id_forgot_password_user_id"

};
