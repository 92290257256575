import keys from "./keys";
import {v4 as uuidv4} from 'uuid';
import jwt_decode from 'jwt-decode';

export const checkAndSetDeviceId = async () => {
    let deviceId = await localStorage.getItem(keys.MTEL_ID_DEVICE_ID);
    if(!deviceId) await localStorage.setItem(keys.MTEL_ID_DEVICE_ID, uuidv4());
};


export const getBrowserName = () => {

    var isOpera = (!!window.opr && !!window.opr.addons) || !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0;
    if(isOpera) return "Opera";

    // Firefox 1.0+
    var isFirefox = typeof InstallTrigger !== 'undefined';
    if(isFirefox) return "Firefox";

    // Safari 3.0+ "[object HTMLElementConstructor]"
    var isSafari = /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof safari !== 'undefined' && window['safari'].pushNotification));
    if(isSafari) return "Safari";

    // Internet Explorer 6-11
    var isIE = /*@cc_on!@*/false || !!document.documentMode;
    if(isIE) return "InternetExplorer";

    // Edge 20+
    var isEdge = !isIE && !!window.StyleMedia;
    if(isEdge) return "Edge";
    // Chrome 1 - 79
    var isChrome = !!window.chrome;

    var isEdgeChromium = isChrome && (navigator.userAgent.indexOf("Edg") != -1);
    if(isEdgeChromium) return "Edge";
    else if(isChrome) return "Chrome";

    return "Unknown";
};

export const cleanRegistrationSessionData = () =>{
    sessionStorage.removeItem(keys.MTEL_ID_REG_PIN);
    sessionStorage.removeItem(keys.MTEL_ID_REG_PASSWORD);
    sessionStorage.removeItem(keys.MTEL_ID_REG_USERNAME);
    sessionStorage.removeItem(keys.MTEL_ID_REG_GUID);
    sessionStorage.removeItem(keys.MTEL_ID_REG_STEP);
}

export const cleanForgotPasswordSessionData = () =>{
    sessionStorage.removeItem(keys.MTEL_ID_REG_PIN);
    sessionStorage.removeItem(keys.MTEL_ID_REG_PASSWORD);
    sessionStorage.removeItem(keys.MTEL_ID_REG_USERNAME);
    sessionStorage.removeItem(keys.MTEL_ID_REG_GUID);
    sessionStorage.removeItem(keys.MTEL_ID_FORGOT_PASSWORD_STEP);
}

export const formatPhoneNumber = (phoneNumber = '') => {
    if (!phoneNumber) {
        return '';
    }
    const regexBA = /^(00|\+)(387)+/;
    const regexMtelShort = /^(06).*/;
    const removeSpacesRegex = /\s/g;
    if (!regexBA.test(phoneNumber) && !regexMtelShort.test(phoneNumber)) {
        return phoneNumber;
    }
    const withoutSpaces = phoneNumber.replace(removeSpacesRegex, '');
    let withoutInternational = withoutSpaces.replace(regexBA, '');
    if (withoutInternational.charAt(0) === '0') {
        withoutInternational = withoutInternational.slice(1);
    }
    return `387${withoutInternational}`;
};

export const checkIfPhoneNumber = (phoneNumber = '') => {
    if (!phoneNumber) {
        return false;
    }

    if(phoneNumber.indexOf("06")==0) return true;
    return false;
}

export const validatePhoneNumber = (phoneNumber = '') => {
    if (!phoneNumber) {
        return false;
    }

    if(phoneNumber.indexOf("06")==0){
        return phoneNumber.match(/\d/g).length===9 || phoneNumber.match(/\d/g).length===10;
    }else if(phoneNumber.indexOf("+")==0){
        return phoneNumber.match(/[+][0-9]+$/g);
    }

    return false;
}

export const decodeJwtAndGetProperty = async (propertyName, keyToken) => {
    let token = localStorage.getItem(keyToken);
    const decodedToken = jwt_decode(token);
    if (decodedToken) return decodedToken[propertyName];
    return null;
};

export const validateStandardInput = (input, max) => {
    if(input.length<2) return false;
    if(input.length>=max) return false;
    return true;
};

export const validateEmail = (input) => {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(input))
    {
        return (true)
    }
    return false;
}

export const formatDate = (d) => {
    let datestring = d.getDate()  + "." + (d.getMonth()+1) + "." + d.getFullYear() + ". " +
        (d.getHours()<10?"0"+d.getHours():d.getHours())+ ":" + (d.getMinutes()<10?"0"+d.getMinutes():d.getMinutes());

    return datestring;
}

export const formatDateWithoutTime = (d) => {
    let datestring = d.getDate()  + "." + (d.getMonth()+1) + "." + d.getFullYear() + ".";
    return datestring;
}

export const getAmountWithoutVatStr = (a) => {
    let x = a/1.17;
    x = (x.toFixed(2)+ " KM").replace(".",",");
    return x;
}

export const getVatStr = (a) => {
    let x = (a/1.17)*0.17;
    x = (x.toFixed(2)+ " KM").replace(".",",");
    return x;
}

export const getAmountStr = (a) => {
    let x = a;
    x = (x.toFixed(2)+ " KM").replace(".",",");
    return x;
}
