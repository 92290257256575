import React, {useContext, useEffect, useRef, useState} from 'react';
import {AuthorizationContext} from "../../../account/context";
import {NavLink, useNavigate} from "react-router-dom";
import {
    fetchAccountInfo,
    fetchPaymentData,
    fetchPaymentHistory,
    fetchPaymentInfo
} from "../../../account/account.service";
import PaymentCard from "../../../components/payment-card";
import ConfirmDialog from "../../../components/confirm-dialog";
import Modal from "../../../components/modal";
import CardPersonalData from "../../../components/addCard/cardPersonalData";
import CardData from "../../../components/addCard/cardData";
import SavedCardSuccess from "../../../components/addCard/saveCardSuccess";
import SavedCardError from "../../../components/addCard/saveCardFailed";
import useAccount from "../hooks/account";
import {formatDate, formatDateWithoutTime} from "../../../common/util";

const IstorijaUplata = (props) => {

    const {setAuthorized, setTokenData, isAuthorized, isLoaded, tokenData} = useContext(AuthorizationContext);
    const { accountInfo, getAccountInfo} = useAccount();
    const [paymentHistoryList, setPaymentHistoryList]= useState([]);

    let navigate = useNavigate();

    useEffect(() => {
        if(isLoaded && !isAuthorized) navigate("/nalog");
    }, [isLoaded]);

    useEffect(() => {
        fetchHistory();
    }, []);

    const fetchHistory = async () => {
        const {
            data: paymentHist
        }= await fetchPaymentHistory();

        setPaymentHistoryList(paymentHist);
    }


    return (
        <div className="lightContent block">
            <div>
                <div className="actionHeader">
                    <div className="actionHeaderTitle">Istorija uplata</div>
                </div>
                <div className="standardText clear">Ovdje možeš pronaći kompletnu istoriju svojih uplata te pregledati pojedinačne račune.</div>
                <div className="billHistoryArea">

                    {paymentHistoryList.length === 0 && <div className="sectionNoContentMessage">
                        <div><b>Nema uplata</b></div>
                    </div> }
                    {paymentHistoryList.length !== 0 &&
                        <div className="billHistoryHeader">
                            <div className="billHistoryInvoiceNo">Broj računa</div>
                            <div className="billHistoryTime">Vrijeme uplate</div>
                            <div className="billHistoryPeriod">Period pretplate</div>
                            <div className="billHistoryCard">Plaćeno sa</div>
                            <div className="billHistoryAmount">Iznos</div>
                            <div className="billHistoryAction"></div>
                        </div>
                    }
                    {paymentHistoryList.map(function(object, i){
                        return <div className="billHistoryRow">
                            <div className="billHistoryCell billHistoryInvoiceNo"><span className="billHistoryCellTitle">Broj računa: &nbsp;</span>{object.orderNumber}</div>
                            <div className="billHistoryCell billHistoryTime"><span className="billHistoryCellTitle">Vrijeme uplate: &nbsp;</span>{formatDate(new Date(object.updated))}</div>
                            <div className="billHistoryCell billHistoryPeriod"><span className="billHistoryCellTitle">Period pretplate: &nbsp;</span>{
                                formatDateWithoutTime(new Date(object.periodStart))+" - "+
                                formatDateWithoutTime(new Date(object.periodEnd))
                            }</div>
                            <div className="billHistoryCell billHistoryCard"><span className="billHistoryCellTitle">Plaćeno sa: &nbsp;</span>{object.creditCardNumber}</div>
                            <div className="billHistoryCell billHistoryAmount"><span className="billHistoryCellTitle">Iznos: &nbsp;</span>{object.amount+ " KM"}</div>
                            <NavLink className="billHistoryAction" to={"/nalog/racun?no="+object.orderNumber} target="_blank">Prikaži račun</NavLink>
                        </div>;
                    })}




                </div>
            </div>
        </div>
    )
};

export default IstorijaUplata;