import { useNavigate } from "react-router-dom";
import React from "react";

const DeaktivirajOntvUspjesno = () => {

    let navigate = useNavigate();

    const onSubmit = async (data) => {
        console.log(data);
        navigate('/nalog');
    }

    return (
        <div id="registerPagePhoneNumber" className="lightContent center">
            <div className="wizardCenterArea">
                <div className="mainTitle spacer spacerUp">ON TV usluga je uspješno deaktivirana</div>
                <div className="standardText">
                    Žao nam je što odlaziš, ali ukoliko se predomisliš uvijek možeš reaktivirati ON TV uslugu kroz podešavanja svog naloga.
                </div>
                <div className="centerWrapper">
                    <button className="mainActionButton center" type="submit" onClick={onSubmit}>Podešavanja naloga</button>
                </div>
            </div>

        </div>
    )
}

export default DeaktivirajOntvUspjesno;