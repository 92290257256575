import React, {useEffect, useRef, useState} from 'react';
import {config as getConfig} from '../../environments/environments';
import {validateEmail, validatePhoneNumber, validateStandardInput} from "../../common/util";
import {sendMonriCardRequest} from "../../account/account.service";

const CardData = (props) => {
    const monriRef = useRef();
    const [clientSecret, setClientSecret] = useState(null);

    const [cardNumberValid, setCardNumberValid] = useState(false);
    const [expirationDateValid, setExpirationDateValid] = useState(false);
    const [cvvValid, setCvvValid] = useState(false);

    const cardNumberFirstEventCall = useRef(true);
    const expirationDateFirstEventCall = useRef(true);
    const cvvFirstEventCall = useRef(true);

    const [card, setCard] = useState(null);


    useEffect(() => {
        let isMobile = false;
        if (props.cardData && props.cardData.paymentSecret && props.cardData.paymentSecret!=clientSecret) {
            setClientSecret(props.cardData.paymentSecret);

            console.log(props.cardData);

            // eslint-disable-next-line no-undef
            let monri = Monri(getConfig().monriAuthenticityToken, {locale: 'sr'});
            let components = monri.components({"clientSecret": props.cardData.paymentSecret});
            let card = components.create('card', {
                tokenizePan: true,
                style: {
                    base: {
                        fontSize: '14px',
                        color: '#504D4D',
                        fontFamily: 'Poppins,sans-serif',
                        textIndent: '6px',
                        backgroundColor: 'transparent',
                        padding: '0px 5px'
                    },
                    label: {
                        base: {
                            textTransform: 'capitalize',
                            fontSize: '16px',
                            letterSpacing: '0',
                            backgroundColor: 'transparent',
                            margin: "0px 0px 15px 0px",
                            textIndent: '0',
                            fontWeight: '300'
                        }
                    },
                    input: {
                        base: {
                            padding: '20px',
                            borderRadius: '7px',
                            fontSize: '16px',
                            backgroundColor: 'white',
                            border: '2px solid transparent',
                            boxShadow: '2px 2px 10px rgba(0, 0, 0, 0.1)',
                        },
                        invalid: {
                            color: '#e63b47'
                        },
                        complete: {
                            color: '#548b54'
                        },
                        empty: {
                            color: '#e63b47'
                        }
                    },
                    rememberCardLabel: {
                        base: {
                            fontSize: '10px',
                            textIndent: "0px",
                            padding: "0px 1px",
                            position: "relative",
                            top: "2px",
                            left: "-5px"
                        }
                    }
                },
            });


            card.addChangeListener('card_number', cardNumberChangeValidator);
            card.addChangeListener('expiry_date', expirationDateChangeValidator);
            card.addChangeListener('cvv', cvvChangeValidator);
            setCard(card);

            card.mount(monriRef.current.id);
        }
    });

    const cardNumberChangeValidator = (ev) => {
        if (!cardNumberFirstEventCall.current)
            setCardNumberValid(ev.valid);
        cardNumberFirstEventCall.current = false;
    }

    const expirationDateChangeValidator = (ev) => {
        if (!expirationDateFirstEventCall.current)
            setExpirationDateValid(ev.valid);
        expirationDateFirstEventCall.current = false;
    }

    const cvvChangeValidator = (ev) => {
        if (!cvvFirstEventCall.current)
            setCvvValid(ev.valid);
        cvvFirstEventCall.current = false;
    }


    const onFinishClick = async () => {
        // eslint-disable-next-line no-undef
        let monri = Monri(getConfig().monriAuthenticityToken, {locale: 'sr'});

        let params = {
            address: props.cardData.card.address,
            fullName: props.cardData.card.fullName,
            city: props.cardData.card.city,
            zip: props.cardData.card.zip,
            phone: props.cardData.card.phone,
            country: props.cardData.card.country,
            email: props.cardData.card.email,
            orderInfo: props.cardData.orderInfo.orderInfo
        };

        monri.confirmPayment(card, params).then((result) => {
            if (result.error) {
                props.onError();
            }else if(result.result.status=="declined") {
                props.onError();
            }else{
                props.onSuccess();
            }
        }).catch(error => {
            console.log(error);
            props.onError();
        });
    }

    const handleError = () => {
        props.onError();
    }


    return (
        <div className="cardRegistrationBody">
            <div className="cardRegistrationTitle">Registracija nove platne kartice</div>
            <div className="cardRegistrationDescription">Da biste aktivirali ON TV uslugu, potrebno je da registrujete i
                sačuvate vašu platnu karticu koja će biti korištena za naplatu. Prilikom registracije biće vam rezervisano
                0,10KM kako bi se provjerila validnost platne kartice. Ovaj iznoz će vam biti vraćen na račun u roku od
                30 dana, u zavisnosti od politika i pravila Vaše banke.
            </div>
            <div className="cardRegistrationPersonalDataBody">
                <div className="cardRegistrationSubtitle">Podaci o kartici</div>
                <div ref={monriRef} id="monri-root"></div>
                <div className="centerWrapper">
                    <button disabled={!cardNumberValid || !expirationDateValid || !cvvValid} className={"mainActionButton spacer center"} onClick={onFinishClick}>Sačuvaj karticu</button>
                </div>
            </div>
        </div>
    );
};

export default CardData;