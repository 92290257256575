import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useWindowScroll } from "react-use";

const ScrollToTop = () => {
    const { y: pageYOffset } = useWindowScroll();
    const [visible, setVisiblity] = useState(false);
    const { pathname } = useLocation();

    useEffect(() => {
        if (pageYOffset > 400) {
            setVisiblity(true);
        } else {
            setVisiblity(false);
        }
    }, [pageYOffset]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    const scrollToTop = () => window.scrollTo({ top: 0, behavior: "smooth" });

    if (!visible) {
        return false;
    }

    return (
        <div
            className="scroll-to-top cursor-pointer text-center"
            onClick={scrollToTop}
        >
            <i class="fa fa-arrow-up"></i>
        </div>
    );
};

export default ScrollToTop;