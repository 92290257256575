import React from 'react';

const SavedCardSuccess = (props) => {

    const onClose = () => {
        props.onFinish();
    }

    return (
        <div className="wizardCenterArea">
            <div className="mainTitle spacer spacerUp">Čestitamo</div>
            <div className="standardText">
                Unesena kartica je uspješno sačuvana. Ovu karticu možeš koristiti ON TV usluge.
            </div>
            <div className="centerWrapper">
                <button className={"mainActionButton spacer center"} onClick={onClose}>Zatvori</button>
            </div>
        </div>
    );
};

export default SavedCardSuccess;