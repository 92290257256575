import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {cleanForgotPasswordSessionData, formatPhoneNumber} from "../../../common/util";
import {useForm} from "react-hook-form";
import {checkUsernameForForgotPassword} from "../../../account/account.service";
import keys from "../../../common/keys";


const ZaboravljenaLozinka = () => {
    const {register, handleSubmit, setValue, getValues} = useForm();
    const [inputInvalid, setInputInvalid] = useState(true);
    const [isLoading, setLoading] = useState(false);
    const [invalidSubmit, setInvalidSubmit] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");


    let navigate = useNavigate();

    const onInputChangeValue = (event) => {
        setInvalidSubmit(false); 
        setValue(event.target.name, event.target.value);
        // console.log(event.target.name);
        // console.log(event.target.value);

        if (getValues("email").length > 0) setInputInvalid(false);
        else setInputInvalid(true);
    };
    let name;

    useEffect(() => {
        return () => {
            cleanForgotPasswordSessionData();
            sessionStorage.setItem(keys.MTEL_ID_FORGOT_PASSWORD_STEP,"1");
        };
    }, []);

    const onSubmit = async (data) => {
        console.log(data);

        if (inputInvalid) return;
        if (isLoading) return;

        let username = data.email;
        setLoading(true);
        try {
            let formattedUsername = username;
            console.log(formattedUsername);
            if (!username.includes('@')) {
              name = "number";
              console.log(name);
                formattedUsername = formatPhoneNumber(username);
                console.log(formattedUsername);
            }else{
              name = "email";
              console.log(name);
            }

            const {
                data: {registrationType, primaryUsername, identificator},
            } = await checkUsernameForForgotPassword({
                username: formattedUsername,
                osType: 1,
            });

            sessionStorage.setItem(keys.MTEL_ID_FORGOT_PASSWORD_REG_TYPE,registrationType);
            sessionStorage.setItem(keys.MTEL_ID_FORGOT_PASSWORD_USERNAME,primaryUsername);
            sessionStorage.setItem(keys.MTEL_ID_FORGOT_PASSWORD_USER_ID,identificator);

            if (registrationType === 'PHONE_NUMBER') {
                navigate('/zaboravljena-lozinka/resetovanje-kod-potvrda-broj-telefona');
            }
            else if (registrationType === 'EMAIL') {
                navigate('/zaboravljena-lozinka/resetovanje-kod-potvrda-email');
            }

        } catch (e) {
            if (e.response.data?.message) {
                setErrorMessage(e.response.data.message);
            }
            setInvalidSubmit(true);
            setLoading(false);
        }

    }
    return (
        <div id="registerPagePhoneNumber" className="lightContent center">
            <div className="wizardCenterArea">
                <div className="mainTitle spacer spacerUp">Zaboravljena lozinka</div>
                <div className="standardText">
                    Unesi e-mail adresu ili broj telefona u polje kako bi započeo proces resetovanja zaboravljene lozinke. Nakon toga, biće ti
                    poslata SMS poruka ili E-mail sa jednokratnim aktivacionim kodom koji trebaš unijeti da bi se nastavio proces
                    promjene lozinke.
                </div>
                <form onSubmit={handleSubmit(onSubmit)} noValidate="novalidate">
                    <div className="standardInputWrapper">
                        <label>E-mail adresa ili broj telefona</label>
                        <input
                            className="full"
                            type="text"
                            name="email"
                            onChange={onInputChangeValue}
                            required
                        />
                        <i className="fa fa-user-circle fa-lg"></i>
                    </div>
                    <div className={"validationErrorMessage"+(invalidSubmit ? " invalid" : "")}><i className="fa fa-warning"></i> {errorMessage}</div>
                    <div className="centerWrapper">
                        <input
                            className={
                                "mainActionButton center" +
                                (inputInvalid ? " invalid" : "") +
                                (isLoading ? " loading" : "")
                            }
                            type="submit"
                            value={"Nastavi"}
                        />
                    </div>
                </form>
            </div>
        </div>
    );
};

export default ZaboravljenaLozinka;